import React, { useState } from "react";
import { Formik, Form } from "formik";
import { FormBuilder, FormDateTimeField, FormTextField } from '@avamae/formbuilder';
import { Spinner } from "components/spinner/Spinner";
import { ApiResponse, FieldRenderers } from "@avamae/formbuilder/dist/FormBuilder";
import * as Yup from "yup";
import { PayoutLimits } from "../payoutLimits/PayoutLimits";
import { parseCCPayoutPayload, postCreditCardPayout } from "./NewCreditCardPayoutApi";
import { SubmitCCPayoutDetails } from "./models";
import "react-datepicker/dist/react-datepicker.css";
import { useMedia } from "use-media";
import { errorHandling } from "components/helpers/formHelpers/ErrorHandling";
import { AmountStateProps } from "components/pages/payout/Payout";
import { FormRow } from "components/formRow/FormRow";
import { SuccessModal, SuccessProps } from "components/successModal/SuccessModal";
import { useStore } from "reducers/Store";

const NewCreditCardPayout: React.FC<AmountStateProps> = ({ amount, currency }) => {
    const store = useStore();
    const [error, setError] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);
    const [success, setSuccess] = useState<SuccessProps | null>(null);
    const isDesktop = useMedia({ minWidth: 850 })

    const initialValues = {
        card__Type: "",
        card__Name: "",
        card__Number: null,
        card__ExpDate: null,
        card__CVV: null,
        card__Currency: currency,
        card__PayoutAmount: amount,
        card__bSameAddress: false,
        card__BillingCountry: "",
        card__BillingState: "",
        card__BillingAddress: "",
        card__BillingCity: "",
        card__BillingZipCode: "",
        card__PayoutBonus: "",
    }
    const handleSubmit = (FormikProps: SubmitCCPayoutDetails) => {
        console.log("FormikProps: ", FormikProps)

        const payload: SubmitCCPayoutDetails = {
            card__Type: FormikProps.card__Type,
            card__Name: FormikProps.card__Name,
            card__Number: FormikProps.card__Number,
            card__ExpDate: FormikProps.card__ExpDate,
            card__CVV: FormikProps.card__CVV,
            card__Currency: FormikProps.card__Currency,
            card__PayoutAmount: FormikProps.card__PayoutAmount,
            card__bSameAddress: FormikProps.card__bSameAddress,
            card__BillingCountry: FormikProps.card__BillingCountry,
            card__BillingState: FormikProps.card__BillingState,
            card__BillingAddress: FormikProps.card__BillingAddress,
            card__BillingCity: FormikProps.card__BillingCity,
            card__BillingZipCode: FormikProps.card__BillingZipCode,
            card__PayoutBonus: FormikProps.card__PayoutBonus,
        };

        (async () => {
            setLoading(true)
            console.log({ payload })
            const result = await postCreditCardPayout(parseCCPayoutPayload(payload, store.playerId))
            if (result.response) {
                console.log("credit card post was a success")
                setSuccess({ html: result.response.details })
            }
            if (result.errors && result.errors.length > 0) {
                console.log(result.errors)
                setError(errorHandling(result.errors[0].code))
            }
        })()
        setLoading(false)
    }

    const validationSchema = Yup.object({
        // card__Type: Yup.string().required("Card type is required"),
        // card__Name: Yup.string().required("Name is required"),
        // card__Number: Yup.number().nullable().required("Card number is required"),
        // card__ExpDate: Yup.string().nullable().required("Expiry date is required"),
        // card__CVV: Yup.number().nullable().required("CVV is required"),
        // card__Currency: Yup.string().required("Currency is required"),
        // card__PayoutAmount: Yup.number().nullable().required("An amount to be payouted is required"),
        // card__BillingCountry: Yup.string().when("card__bSameAddress", { is: true, then: null, otherwise: Yup.string().required("Country is required") }),
        // card__BillingState: Yup.string().when("card__bSameAddress", { is: true, then: null, otherwise: Yup.string().required("Please choose a state") }),
        // card__BillingAddress: Yup.string().when("card__bSameAddress", { is: true, then: null, otherwise: Yup.string().required("An address is required") }),
        // card__BillingCity: Yup.string().when("card__bSameAddress", { is: true, then: null, otherwise: Yup.string().required("Please choose a city") }),
        // card__BillingZipCode: Yup.string().when("card__bSameAddress", { is: true, then: null, otherwise: Yup.string().required("Zip code is required") }),
        // card__PayoutBonus: Yup.string().required("Please select a bonus"),
    })

    const cardTypeDropdown = [{ value: "VISA", label: "VISA" }, { value: "Mastercard", label: "Mastercard" }, { value: "American Express", label: "American Express" }]
    const cardCurrencyDropdown = [{ value: "USD", label: "USD" }, { value: "GBP", label: "GBP" }, { value: "EUR", label: "EUR" }]

    const countryDropdown = [{ value: "US", label: "US" }]
    const stateDropdown = [{ value: "Alaska", label: "Alaska" }]

    const cardFieldRenderers: FieldRenderers<any> = {
        card__ExpDate: (i, field) => (
            <div className="HalfWidthFields">
                <FormDateTimeField
                    fieldName={field.name as string}
                    label={field.label}
                    datePickerProps={{ dateFormat: "MM-yy", showMonthYearPicker: true }}
                />
                <FormTextField fieldName={"card__CVV"} label={"CVV"} />
            </div>
        )
    }

    const billingFieldRenderers = (formikProps: any): FieldRenderers<any> => ({
        card__bSameAddress: (i, field) => (
            <div className="FormBox FormSideBySide">
                <label className="container">{field.label}
                    <input
                        key={i}
                        name={field.name as string}
                        type="checkbox"
                        id={field.name.toString()}
                        checked={!!formikProps.values[field.name]}
                        onChange={() => formikProps.setFieldValue(field.name, !formikProps.values[field.name])}
                    />
                    <span className="checkmark"></span>
                </label>
            </div>
        )
    })

    const mobCardFieldRenderers: FieldRenderers<any> = {
        card__ExpDate: (i, field) => (
            <div className="HalfWidthFields">
                <FormDateTimeField
                    fieldName={field.name as string}
                    label={field.label}
                />
                <FormTextField fieldName={"card__CVV"} label={"CVV"} />
            </div>
        )
    }

    const mobBillingFieldRenderers = (formikProps: any): FieldRenderers<any> => ({
        card__bSameAddress: (i, field) => (
            <div className="FormBox FormSideBySide">
                <label className="container">{field.label}
                    <input
                        key={i}
                        name={field.name as string}
                        type="checkbox"
                        id={field.name.toString()}
                        checked={!!formikProps.values[field.name]}
                        onChange={() => formikProps.setFieldValue(field.name, !formikProps.values[field.name])}
                    />
                    <span className="checkmark"></span>
                </label>
            </div>
        )
    })



    return (
        <>
            <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={validationSchema}
            >
                {formikProps => (
                    <Form>
                        <>
                            {isDesktop ?
                                <>
                                    <div className="FormContainer">

                                        <div className="FormRow FormRowHeaders">
                                            <h3>Card Details</h3>
                                            <h3>Billing Details</h3>
                                        </div>
                                        <FormRow data={dataRowOne} additionalData={{ card__Type: cardTypeDropdown }} overrideFormFieldRenderer={billingFieldRenderers(formikProps)}
                                        />
                                        <FormRow data={dataRowTwo} additionalData={{ card__BillingCountry: countryDropdown }} />
                                        <FormRow data={dataRowThree} additionalData={{ card__BillingState: stateDropdown }} />
                                        <FormRow data={dataRowFour} hiddenFields={{ card__CVV: true }} overrideFormFieldRenderer={cardFieldRenderers} />
                                        <FormRow data={dataRowFive} additionalData={{ card__Currency: cardCurrencyDropdown }} readOnlyFields={{ card__Currency: true }} />
                                        <FormRow data={dataRowSix} readOnlyFields={{ card__PayoutAmount: true }} />
                                        <div className="Divider"></div>
                                    </div>
                                    <PayoutLimits minimum={45} maximum={1500} endText={false} />
                                    <div className="SubmitContainer">
                                        <button type="submit" className="SubmitBtn">{loading ? <Spinner color="white" /> : 'Payout'}</button>
                                    </div>
                                    {error &&
                                        <div className="ErrorMessage">
                                            {error}
                                        </div>
                                    }
                                    {success &&
                                        <SuccessModal html={success.html} address={success.address} setSuccess={setSuccess} paymentType="eWallet" type="deposit" />
                                    }
                                </>
                                :
                                <>
                                    <div className="FormContainer">
                                        <div className="FormSectionMob">
                                            <h3>Card Details</h3>
                                            <FormBuilder
                                                data={mobCardData}
                                                overrideFormFieldRenderer={mobCardFieldRenderers}
                                                loadingFallback={<Spinner />}
                                                additionalData={{ card__Type: cardTypeDropdown, card__Currency: cardCurrencyDropdown }}
                                                hiddenFields={{ card__CVV: true }}
                                                readOnlyFields={{ card__PayoutAmount: true, card__Currency: true }}
                                            />
                                        </div>
                                        <div className="FormSectionMob">
                                            <h3>Billing Details</h3>
                                            <FormBuilder
                                                data={mobBillingData}
                                                overrideFormFieldRenderer={mobBillingFieldRenderers(formikProps)}
                                                loadingFallback={<Spinner />}
                                                additionalData={{ card__BillingCountry: countryDropdown, card__BillingState: stateDropdown }}
                                                fieldProps={{ card__ExpDate: { dateFormat: "MM:yyyy" } }}
                                            />
                                        </div>
                                        <PayoutLimits minimum={45} maximum={1500} endText={false} />
                                    </div>
                                    <div className="SubmitContainer">
                                        <button type="submit" className="SubmitBtn">{loading ? <Spinner color="white" /> : 'Payout'}</button>
                                    </div>
                                    {error &&
                                        <div className="ErrorMessage">
                                            {error}
                                        </div>
                                    }
                                    {success &&
                                        <SuccessModal html={success.html} address={success.address} setSuccess={setSuccess} paymentType="eWallet" type="deposit" />
                                    }
                                </>
                            }
                        </>
                    </Form>
                )}
            </Formik>
        </>
    )
}

export { NewCreditCardPayout };

const dataRowOne: ApiResponse<any> = {
    id: 0,
    details: {},
    metadata: [
        {
            name: "card__Type",
            type: "singleselect",
            customType: null,
            label: "Card type",
            children: []
        },
        {
            name: "card__bSameAddress",
            type: "custom",
            customType: null,
            label: "Tick this box if your home address is the same as billing address",
            children: []
        },
    ],
    status: "1",
    errors: []
}
const dataRowTwo: ApiResponse<any> = {
    id: 0,
    details: {},
    metadata: [
        {
            name: "card__Name",
            type: "text",
            customType: null,
            label: "Cardholder's name",
            children: []
        },
        {
            name: "card__BillingCountry",
            type: "singleselect",
            label: "Address",
            children: []
        },
    ],
    status: "1",
    errors: []
}
const dataRowThree: ApiResponse<any> = {
    id: 0,
    details: {},
    metadata: [
        {
            name: "card__Number",
            type: "number",
            customType: null,
            label: "Credit card number",
            children: []
        },
        {
            name: "card__BillingState",
            type: "singleselect",
            label: "State",
            children: []
        },
    ],
    status: "1",
    errors: []
}
const dataRowFour: ApiResponse<any> = {
    id: 0,
    details: {},
    metadata: [
        {
            name: "card__ExpDate",
            type: "custom",
            customType: null,
            label: "Exp Date",
            children: []
        },
        {
            name: "card__CVV",
            type: "number",
            customType: null,
            label: "CVV",
            children: []
        },
        {
            name: "card__BillingAddress",
            type: "text",
            customType: null,
            label: "Address",
            children: []
        },
    ],
    status: "1",
    errors: []
}
const dataRowFive: ApiResponse<any> = {
    id: 0,
    details: {},
    metadata: [
        {
            name: "card__Currency",
            type: "text",
            customType: null,
            label: "Currency",
            children: []
        },
        {
            name: "card__BillingCity",
            type: "text",
            customType: null,
            label: "City",
            children: []
        },
    ],
    status: "1",
    errors: []
}
const dataRowSix: ApiResponse<any> = {
    id: 0,
    details: {},
    metadata: [
        {
            name: "card__PayoutAmount",
            type: "number",
            customType: null,
            label: "Amount",
            children: []
        },
        {
            name: "card__BillingZipCode",
            type: "text",
            customType: null,
            label: "ZIP code",
            children: []
        },
    ],
    status: "1",
    errors: []
}


const mobCardData: ApiResponse = {
    id: 0,
    details: {},
    metadata: [
        {
            name: "card__Type",
            type: "singleselect",
            customType: null,
            label: "Card type",
            children: []
        },
        {
            name: "card__Name",
            type: "text",
            customType: null,
            label: "Cardholder's name",
            children: []
        },
        {
            name: "card__Number",
            type: "text",
            customType: null,
            label: "Credit card number",
            children: []
        },
        {
            name: "card__ExpDate",
            type: "custom",
            customType: null,
            label: "Exp Date",
            children: []
        },
        {
            name: "card__CVV",
            type: "text",
            customType: null,
            label: "CVV",
            children: []
        },
        {
            name: "card__Currency",
            type: "text",
            customType: null,
            label: "Currency",
            children: []
        },
        {
            name: "card__PayoutAmount",
            type: "text",
            customType: null,
            label: "Amount",
            children: []
        },

    ],
    status: "1",
    errors: []
}
const mobBillingData: ApiResponse = {
    id: 0,
    details: {},
    metadata: [
        {
            name: "card__bSameAddress",
            type: "custom",
            customType: null,
            label: "Tick this box if your home address is the same as billing address",
            children: []
        },
        {
            name: "card__BillingCountry",
            type: "singleselect",
            label: "Address",
            children: []
        },
        {
            name: "card__BillingState",
            type: "singleselect",
            label: "State",
            children: []
        },
        {
            name: "card__Address",
            type: "text",
            customType: null,
            label: "Address",
            children: []
        },
        {
            name: "card__City",
            type: "text",
            customType: null,
            label: "City",
            children: []
        },
        {
            name: "card__ZipCode",
            type: "text",
            customType: null,
            label: "ZIP code",
            children: []
        },
    ],
    status: "1",
    errors: []
}