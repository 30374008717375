import React from "react";
import { MoneyGramDeposit } from "../moneyGramDeposit/MoneyGramDeposit";

//In future, if more 'other' payments are added, this can be set-up the same as 'EWalletDeposit' and Cryptos
const OtherDeposit = () => {
    return (
        <MoneyGramDeposit minimum={100} maximum={500} />
    )
}

export { OtherDeposit };