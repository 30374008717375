import axios, { AxiosError, AxiosResponse } from "axios";
import {
  saveTokenResource,
  loadTokenResource,
  TokenResource,
} from "helpers/auth";
// import { signOut as signOutCreator } from "reducers/auth";
import { store } from "./index";
import { BASE_URL, endpoints } from "config";
import { FormField } from "@avamae/formbuilder";
import { createUseFetch } from "@avamae/use-fetch";
import { addSeconds } from "date-fns";

// const signOut = () =>
  // signOutCreator("Your session has expired. Please sign in again.");

// const { refreshAccessToken: refreshEndpoint } = endpoints.auth;

/**
 * Custom axios instance with base url set, and some interceptors to
 * handle auth headers.
 *
 */
const instance = axios.create({
  baseURL: BASE_URL,
});

/**
 * A function that, given an access token, will modify an axios request
 * config and re-run the request.
 */
type RequestCallback = (token: string) => void;

/**
 * Some mutable state for a queue of requests. If we're already in the middle of
 * requesting a new token, we want to hold other requests in a queue, then make
 * them with the new token once we get it.
 */
class RequestHandler {
  isFetchingToken: boolean;
  private pendingRequests: RequestCallback[];

  constructor() {
    this.isFetchingToken = false;
    this.pendingRequests = [];
  }

  addToQueue = (callback: RequestCallback) => {
    this.pendingRequests.push(callback);
  };

  onTokenFetched = (token: string) => {
    this.pendingRequests.forEach((cb) => cb(token));
    this.clearQueue();
  };

  clearQueue = () => {
    this.pendingRequests = [];
    this.isFetchingToken = false;
  };
}

const reqHandler = new RequestHandler();

// Before each request, attach the stored token if it exists.
instance.interceptors.request.use((request) => {
  try {
    const accessToken = localStorage.getItem("AT");
    if (!accessToken) {
      throw new Error();

    }
    const authHeader = { Authorization: `Bearer ${accessToken}` };
    return { ...request, headers: { ...request.headers, ...authHeader } };
  } catch (err) {
    return request;
  }
});

// After each request, if it comes back with a 401 error, refresh token and then retry.
// instance.interceptors.response.use(
//   (fulfilled) => {
//     return fulfilled;
//   },
//   (rejected: AxiosError) => {
//     const status = rejected.response?.status;

//     if (status === 401 /*&& has the expired token header*/) {
//       // Refresh access token.
//       return refreshAccessToken(rejected);
//     }

//     // It's not an authorisation issue, just pass on the rejection.
//     // store.dispatch(signOut());
//     return Promise.reject(rejected);
//   }
// );

/**
 * Intercept the error, and replace it with a promise (awaitingNewToken), which
 * on construction will add a callback to our RequestHandler's queue.
 *
 * The first time the request handler receives one of these additions to its
 * queue, it will attempt to fetch a new access token. It stores up pending
 * requests until the new token arrives. To the initial callers it looks like
 * their request is waiting for a response from the server.
 *
 * Once the request handler has fetched a new access token, it will run every
 * callback in its queue. The callback takes an original request's config,
 * changes it to use the new access token, and remakes the request. It then
 * resolves the promise made in awaitingNewToken. The response to this new
 * request is thereby delivered to the original caller. The queue is then cleared.
 *
 * To that initial caller, it just looks like its original request took a while
 * to resolve.
 */
const refreshAccessToken = async (rejected: AxiosError) => {
  type RefreshResponse = {
    status: string;
    accessToken: string;
    tokenType: string;
    expiresIn: string;
    refreshToken: string;
  };

  try {
    const { response: errorResponse } = rejected;

    // Extract the refresh token from LS and make sure it's there.
    // Otherwise, just return the error.

    // const resource = loadTokenResource();
    // if (resource == null) {
    //   store.dispatch(signOut());
    //   return Promise.reject(rejected);
    // }

    // Build a promise that will be handed to the caller instead of the error.
    const awaitingNewToken = new Promise((resolve) => {
      reqHandler.addToQueue((token) => {
        if (errorResponse) {
          errorResponse.config.headers.Authorization = `Bearer ${token}`;
          resolve(axios(errorResponse.config));
        }
      });
    });

    // Check if we're already trying to fetch a replacement access token.
    // if (!reqHandler.isFetchingToken) {
    //   reqHandler.isFetchingToken = true;
    //   const data = {
    //     grantType: "refresh_token",
    //     accessToken: resource.accessToken,
    //     refreshToken: resource.refreshToken,
    //   };

    //   const response = await axios.post<
    //     typeof data,
    //     AxiosResponse<{ errors: any } & RefreshResponse>
    //   >(BASE_URL + refreshEndpoint, data);

    //   if (
    //     !response.data ||
    //     (response.data.errors && response.data.errors.length > 0)
    //   ) {
    //     // The refresh request failed, reset the queue, sign the
    //     // user out and return the error.
    //     reqHandler.clearQueue();
    //     store.dispatch(signOut());
    //     return Promise.reject(rejected);
    //   }

    //   // The refresh request succeeded, save the token details for future
    //   // requests and make the queued requests again.
    //   const { accessToken, refreshToken, expiresIn } = response.data;
    //   const refreshTokenExpires = addSeconds(
    //     new Date(),
    //     parseInt(expiresIn, 10)
    //   );
    //   saveTokenResource({
    //     accessToken,
    //     refreshToken,
    //     refreshTokenExpires,
    //   });
    //   reqHandler.onTokenFetched(accessToken);
    // }

    // Return the promise, which will fulfill when we have a new token,
    // instead of the error.
    return awaitingNewToken;
  } catch (error) {
    // Something went generically wrong, return this error and signout.
    // store.dispatch(signOut());
    return Promise.reject(error);
  }
};

export default instance;

export const useFetch = createUseFetch(instance);

export type ErrorMessage = {
  type: any;
  fieldName: string;
  messageText: string;
};

export interface ApiResponse<M = any, T = any> {
  id: number;
  details: T;
  metadata: FormField<M>[];
  status: "0" | "1";
  errors: ErrorMessage[];
}
