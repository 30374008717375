import React from 'react';
import { UseCryptoActions } from './CryptoDeposit';
import BitcoinLogo from '../../../assets/Img_Logo_Bitcoin.png';
import BitcoinCashLogo from '../../../assets/Img_Logo_BitcoinCash.png';
import BitcoinGoldLogo from '../../../assets/Img_Logo_BitcoinGold.png';
import DashLogo from '../../../assets/Img_Logo_Dash.png';
import EthereumLogo from '../../../assets/Img_Logo_Ethereum.png';
import EthereumClassicLogo from '../../../assets/Img_Logo_EthereumClassic.png';
import LitecoinLogo from '../../../assets/Img_Logo_Litecoin.png';
import MoneyGramLogo from '../../../assets/Img_Logo_MoneyGram.png';
import QTUMLogo from '../../../assets/Img_Logo_QTUM.png';
import RippleLogo from '../../../assets/Img_Logo_Ripple.png';

const cryptoItems = [
    { name: "litecoin", icon: <img src={LitecoinLogo} alt="Litecoin-Logo"></img> },
    { name: "ethereum", icon: <img src={EthereumLogo} alt="Ethereum-Logo"></img> },
    { name: "dash", icon: <img src={DashLogo} alt="Dash-Logo"></img> },
    { name: "btc cash", icon: <img src={BitcoinCashLogo} alt="BitcoinCash-Logo"></img> },
    { name: "ripple", icon: <img src={RippleLogo} alt="Ripple-Logo"></img> },
    { name: "qtum", icon: <img src={QTUMLogo} alt="QTUM-Logo"></img> },
    { name: "eth classic", icon: <img src={EthereumClassicLogo} alt="EthereumClassic-Logo"></img> },
    { name: "btc gold", icon: <img src={BitcoinGoldLogo} alt="BitcoinGold-Logo"></img> },
]

const CryptoItemList: React.FC<{ actions: UseCryptoActions }> = ({ actions }) => {
    return (
        <div className="CryptoSelect">
            {
                cryptoItems.map((c) => {
                    return (
                        <div className="CryptoItem">
                            <h4>{c.name}</h4>
                            <button onClick={actions.setSelected(c.name)}>{c.icon}</button>
                        </div>
                    )
                })
            }
        </div>
    )
}

export { CryptoItemList };