import { endpoints } from "config";
import { CreditCardDepositPayload, SubmitCCDepositDetails } from "./models";
import axios from "api";
import { getMonth, getYear } from "date-fns";

type Error = {
  code: string;
  message: string;
  parameterName: string | null;
  type: string;
};

type ApiReturn<T = any> = {
  response: T | null;
  errors: Error[] | null;
};

const depositCCUrl = endpoints.deposit.deposit;

const postCreditCardDeposit = async (
  payload: CreditCardDepositPayload
): Promise<ApiReturn> => {
  try {
    const res = await axios.post(depositCCUrl, payload);
    if (res.data.result && res.data.result.bSuccessful === true) {
      return {
        response: res.data.result,
        errors: null,
      };
    } else {
      return {
        response: res.data.result && res.data.result.details,
        errors: res.data.errors,
      };
    }
  } catch (error) {
    return {
      response: null,
      errors: (error as any).response,
    };
  }
};

export const parseCCDepositPayload = (
  payload: SubmitCCDepositDetails,
  playerId: string
): CreditCardDepositPayload => {
  const parsedDto: CreditCardDepositPayload = {
    type: "CreditCard",
    amount: payload.card__DepositAmount as number,
    currency: payload.card__Currency,
    depositData: {
      NameOnCard: payload.card__Name,
      CreditCardNumber: payload.card__Number
        ? payload.card__Number.toString()
        : null,
      CreditCardCvv: payload.card__CVV ? payload.card__CVV.toString() : null,
      CreditCardExpiryYear: payload.card__ExpDate
        ? getYear(payload.card__ExpDate).toString()
        : null, //needs changing
      CreditCardExpiryMonth: payload.card__ExpDate
        ? getMonth(payload.card__ExpDate).toString()
        : null, //needs changing
      CreditCardType: payload.card__Type,
    },
    playerId: playerId,
    additionalData: {
      BonusCode: payload.card__DepositBonus,
    },
  };
  return parsedDto;
};

export { postCreditCardDeposit };
