import { State, Action, Type } from "./models";
import { produce } from "immer";

export const initialState: State = {
  path: "/deposit",
  tab: "credit card",
  playerId: "",
  viewModel: [],
};

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case Type.changePage:
      return produce(state, (draft) => {
        draft.path = action.payload;
      });
    case Type.changeTab:
      return produce(state, (draft) => {
        draft.tab = action.payload;
      });
    case Type.storePlayerId:
      return produce(state, (draft) => {
        draft.playerId = action.payload;
      });
    case Type.storeViewModel:
      return produce(state, (draft) => {
        draft.viewModel = action.payload;
      });
    default:
      return state;
  }
};
