import React from "react";
import { initialState, reducer } from "./reducer";
import { State, Action } from "./models";

/** Put state and dispatch in context to be used by other components */
const StoreContext = React.createContext<any>(initialState);
const StoreDispatchContext = React.createContext<
  React.Dispatch<any> | undefined
>(undefined);

const Store: React.FC = ({ children }) => {
  const [state, dispatch] = React.useReducer<typeof reducer>(
    reducer,
    initialState
  );
  return (
    <StoreContext.Provider value={state}>
      <StoreDispatchContext.Provider value={dispatch}>
        {children}
      </StoreDispatchContext.Provider>
    </StoreContext.Provider>
  );
};
const useStore = (): State => {
  const stateContext = React.useContext(StoreContext);
  if (stateContext === undefined) {
    throw new Error("useStore must be used within a StoreContextProvider");
  }
  return stateContext;
};
const useDispatch = (): React.Dispatch<Action> => {
  const dispatchContext = React.useContext(StoreDispatchContext);
  if (dispatchContext === undefined) {
    throw new Error("useDispatch must be used within a StoreContextProvider");
  }
  return dispatchContext;
};
export { Store, useStore, useDispatch };
