import React, { useState } from "react";
import { InitialPayoutPage } from "components/payoutComponents/initialPayoutPage/InitialPayoutPage";
import { NavItem } from "components/navItem/NavItem";
import { PayoutTabRouter } from "components/mainRouter/MainRouter";


export type AmountStateProps = {
    currency: string;
    amount: number;
}



const Payout = () => {
    const initialState = {
        currency: "",
        amount: 0,
    }
    const [amount, setAmount] = useState<AmountStateProps>(initialState)
    const [isInitialPage, setIsInitialPage] = useState<boolean>(true);

    return (
        <section className={isInitialPage ? "Page Payout" : "Page"}>
            <h2>Payout</h2>
            <div className="Main">
                {isInitialPage ?
                    <InitialPayoutPage setAmount={setAmount} setIsInitialPage={setIsInitialPage} amount={amount} />
                    :
                    <>
                        <nav className="Nav">
                            <div className="Links">
                                <NavItem active="bitcoin" />
                                <NavItem active="other" />
                                <NavItem active="credit card" />
                                <NavItem active="credit card new" />
                            </div>
                            <div className="BorderBottom"></div>
                        </nav>
                        <div className="Content">
                            <PayoutTabRouter amount={amount} />
                        </div>
                    </>
                }

            </div>
        </section>
    )
}

export { Payout }