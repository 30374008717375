export const errorHandling = (errorCode: string) => {
  switch (errorCode) {
    case "ERR1000":
      return "The transaction could not be completed at this time";
    case "ERR1002":
      return "A request to a third-party service failed";
    case "ERR1003":
      return "An invalid request parameter was provided";
    case "ERR1005":
      return "Login failed";
    case "ERR1006":
      return "The specified client is invalid";
    case "ERR1007":
      return "The specified player is invalid";
    case "ERR1008":
      return "The specified player has already been register";
    case "ERR1009":
      return "An invalid transaction type was requested";
    case "ERR1010":
      return "An invalid deposit type was requested";
    case "ERR1011":
      return "An invalid payout type was requested";
    case "ERR1012":
      return "An invalid info type was requested";
    case "ERR1013":
      return "Bad request";
    case "ERR1014":
      return "An internal server error occurred";
    case "ERR1015":
      return "The specified currency is invalid for this PSP";
    case "ERR1016":
      return "The provided card has expired";
    case "ERR1018":
      return "A request to a third-party service cannot be made at this time";
    default:
      return "Oops, something went wrong. Please try again later.";
  }
};
