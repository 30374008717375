import React, { useState } from 'react';
import { storePlayerId, storeViewModel } from 'reducers/actions';
import { useDispatch } from 'reducers/Store';
import { PageRouter } from 'components/mainRouter/MainRouter';
import queryString from "query-string";
import WarningIcon from './assets/Icon_Warning.png';

const App = () => {
  const dispatch = useDispatch();
  const search = window.location ? window.location.search : "";
  const parsed = queryString.parse(search)
  console.log("parsed: ", parsed)
  const playerId = parsed.player as string;
  const token = parsed.token as string;
  const method = parsed.method as string;

  //get viewModel from parent site
  window.addEventListener('message', (event: any) => {
    console.log(event);
    // dispatch(storeViewModel(event.data.payload));
  }, false)

  const [invalidToken, setInvalidToken] = useState<boolean>(false);

  // React.useEffect(() => {
  //   const username = "DSICustomerCore";
  //   const password = "J46b7bucsjVr";
  //   store.dispatch(signIn({ username, password }));
  // }, [])
  React.useEffect(() => {
    if (token) {
      setInvalidToken(false);
      localStorage.setItem("AT", token);
    } else {
      setInvalidToken(true);
    }
    if (playerId) {
      dispatch(storePlayerId(playerId));
    }
  }, [playerId, token, dispatch])

  return (
    <>
      {invalidToken ?
        <div className="ErrorPage">
          <div className="ErrorContainer">
            <img src={WarningIcon} alt="WarningIcon"></img>
            Oops, something went wrong, please refresh the page and try again.
          </div>
        </div>
        :
        <div className="App">
          <PageRouter method={method} />
        </div>
      }
    </>
  );
}

export default App;
