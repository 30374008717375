import React from "react";


type PayoutLimitsProps = {
    minimum: number;
    maximum: number;
    endText: boolean;
}

const PayoutLimits: React.FC<PayoutLimitsProps> = ({ minimum, maximum, endText }) => {
    return (
        <div className="PayoutLimits">
            <p>Payout Limits: Minimum {minimum} USD Maximum {maximum} USD {endText && <span>(Bitcoin Payouts have a limit of $1000 per request)</span>}</p>
        </div>
    )
}

export { PayoutLimits }