import React from "react";
import { useStore, useDispatch } from "reducers/Store";
import { changeTab } from "reducers/actions";

interface NavItemProps {
    active: string;
}


const NavItem: React.FC<NavItemProps> = ({ active }) => {
    const store = useStore();
    const dispatch = useDispatch();

    const handleTab = (s: string) => () => {
        dispatch(changeTab(s))
    };

    return (
        <button className={store.tab === active ? "NavItem Active" : "NavItem"} onClick={handleTab(active)}>{active}</button>
    )
}

export { NavItem };