import { endpoints } from "config";
import axios from "api";
import {
  MoneyGramDepositPayload,
  MoneyGramStartDeposit,
  MoneyGramConfirmDeposit,
} from "./models";

type Error = {
  code: string;
  message: string;
  parameterName: string | null;
  type: string;
};

type ApiReturn<T = any> = {
  response: T | null;
  errors: Error[] | null;
  bSuccessful?: boolean;
};

const depositUrl = endpoints.deposit.deposit;

const postMoneyGramDeposit = async (
  payload: MoneyGramDepositPayload<
    MoneyGramStartDeposit | MoneyGramConfirmDeposit
  >
): Promise<ApiReturn> => {
  try {
    const res = await axios.post(depositUrl, payload);
    if (res.data.result && res.data.result.bSuccessful === true) {
      console.log("res:", res);
      return {
        response: res.data.result,
        errors: null,
      };
    } else {
      console.log("else res:", res);
      return {
        response: res.data.result && res.data.result.details,
        bSuccessful: res.data.result && res.data.result.bSuccessful,
        errors: res.data.errors,
      };
    }
  } catch (error) {
    return {
      response: null,
      errors: (error as any).response.data.errors,
    };
  }
};

// export const parseEWalletDepositPayload = (
//     payload: MoneyGramDepositDetails
// ): MoneyGramDepositPayload<MoneyGramStartDeposit | MoneyGramConfirmDeposit> => {
//     const parsedDto: MoneyGramDepositPayload<MoneyGramStartDeposit | MoneyGramConfirmDeposit> = {
//         type: "MoneyGram",
//         amount: payload.moneyGram__Amount as number,
//         currency: payload.moneyGram__Currency,
//         depositData: {

//         },
//         playerId: "SW364495",
//     };
//     return parsedDto;
// };

export { postMoneyGramDeposit };
