// import { SIGN_IN } from "./auth";
import { Action, Type, ViewModel } from "./models";

export const changeTab = (t: string): Action => ({
  type: Type.changeTab,
  payload: t,
});

export const changePage = (p: string): Action => ({
  type: Type.changePage,
  payload: p,
});

export const storePlayerId = (playerId: string): Action => ({
  type: Type.storePlayerId,
  payload: playerId,
});
export const storeViewModel = (payload: ViewModel[]): Action => ({
  type: Type.storeViewModel,
  payload,
});
