import { endpoints } from "config";
import { CreditCardPayoutPayload, SubmitCCPayoutDetails } from "./models";
import axios from "api";
import { getMonth, getYear } from "date-fns";

type Error = {
  code: string;
  message: string;
  parameterName: string | null;
  type: string;
};

type ApiReturn<T = any> = {
  response: T | null;
  errors: Error[] | null;
};

const payoutCCUrl = endpoints.payout.payout;

const postCreditCardPayout = async (
  payload: CreditCardPayoutPayload
): Promise<ApiReturn> => {
  try {
    const res = await axios.post(payoutCCUrl, payload);
    if (res.data.result && res.data.result.bSuccessful === true) {
      console.log("res:", res);
      return {
        response: res.data.result,
        errors: null,
      };
    } else {
      console.log("else res:", res);
      return {
        response: res.data.result,
        errors: res.data.errors,
      };
    }
  } catch (error) {
    console.log({ error });
    return {
      response: null,
      errors: error as any,
    };
  }
};

export const parseCCPayoutPayload = (
  payload: SubmitCCPayoutDetails,
  playerId: string
): CreditCardPayoutPayload => {
  const parsedDto: CreditCardPayoutPayload = {
    type: "CreditCard",
    amount: payload.card__PayoutAmount as number,
    currency: payload.card__Currency,
    payoutData: {
      NameOnCard: payload.card__Name,
      CreditCardNumber: payload.card__Number
        ? payload.card__Number.toString()
        : null,
      CreditCardCvv: payload.card__CVV ? payload.card__CVV.toString() : null,
      CreditCardExpiryYear: payload.card__ExpDate
        ? getYear(payload.card__ExpDate).toString()
        : null, //needs changing
      CreditCardExpiryMonth: payload.card__ExpDate
        ? getMonth(payload.card__ExpDate).toString()
        : null, //needs changing
      CreditCardType: payload.card__Type,
    },
    playerId: playerId,
  };
  return parsedDto;
};

// const getEventDetails = async (eventid: number): Promise<ApiReturn> => {
//   try {
//     const config: AxiosRequestConfig = {
//       params: {
//         eventid,
//       },
//     };
//     const res = await axios.get(, config);
//     if (res.data.status === `1`) {
//       return {
//         response: res.data.details,
//         errors: null,
//       };
//     } else {
//       return {
//         response: res.data.details,
//         errors: res.data.errors,
//       };
//     }
//   } catch (error) {
//     return {
//       response: null,
//       errors: (error as any).response.data.errors ?? [
//         { messageCode: "Unknown error", fieldName: "" },
//       ],
//     };
//   }
// };

export { postCreditCardPayout };
