import React from "react";

import { CryptoItemList } from "./CryptoItemList";
import { EWalletDeposit } from "../eWalletDeposit/EWalletDeposit";

const CryptoDeposit = () => {
    const [actions, crypto] = useCrypto();

    return (
        <>
            {crypto === null ?
                <CryptoItemList actions={actions} />
                :
                <EWalletDeposit actions={actions} crypto={crypto} minimum={20} maximum={10000} />
            }
        </>
    )
}

export { CryptoDeposit };

export type UseCryptoActions = {
    clearSelected: () => void;
    isSelected: (s: string | null) => boolean;
    setSelected: (s: string) => () => void;
}

type UseCrypto = [
    UseCryptoActions,
    string | null,
]

const useCrypto = (initial?: string | null): UseCrypto => {
    const [crypto, setCrypto] = React.useState<string | null>(initial ?? null);

    const isSelected = React.useCallback((selected: string | null) => crypto === selected, [crypto]);

    const setSelected = React.useCallback((selection: string) => () => setCrypto(selection), [setCrypto]);

    const clearSelected = React.useCallback(() => setCrypto(null), [setCrypto]);

    const actions: UseCryptoActions = React.useMemo(() => ({
        isSelected,
        setSelected,
        clearSelected
    }), [
        isSelected,
        setSelected,
        clearSelected
    ])

    return [actions, crypto];

}