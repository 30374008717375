import { endpoints } from "config";
import { EWalletDepositDetails, EWalletDepositPayload } from "./models";
import axios from "api";

type Error = {
  code: string;
  message: string;
  parameterName: string | null;
  type: string;
};

type ApiReturn<T = any> = {
  response: T | null;
  errors: Error[] | null;
};

const depositUrl = endpoints.deposit.deposit;

const postEWalletDeposit = async (
  payload: EWalletDepositPayload
): Promise<ApiReturn> => {
  try {
    const res = await axios.post(depositUrl, payload);
    if (res.data.result && res.data.result.bSuccessful === true) {
      console.log("res:", res);
      return {
        response: res.data.result,
        errors: null,
      };
    } else {
      console.log("else res:", res);
      return {
        response: res.data.result && res.data.result.details,
        errors: res.data.errors,
      };
    }
  } catch (error) {
    return {
      response: null,
      errors: (error as any).response.data.errors,
    };
  }
};

export const parseEWalletDepositPayload = (
  payload: EWalletDepositDetails,
  playerId: string
): EWalletDepositPayload => {
  const parsedDto: EWalletDepositPayload = {
    type: "EWallet",
    amount: payload.eWallet__Amount as number,
    currency: payload.eWallet__Currency,
    depositData: {
      EWalletAccount: "test",
      EWalletPassword: "1234",
    },
    playerId: playerId,
    additionalData: {
      BonusCode: payload.eWallet__Bonus,
    },
  };
  return parsedDto;
};

export { postEWalletDeposit };
