import { AmountStateProps } from "components/pages/payout/Payout";
import { Formik, Form } from "formik";
import React, { SetStateAction } from "react";
import { PayoutLimits } from "../payoutLimits/PayoutLimits";
import * as Yup from "yup";
import { FormBuilder } from "@avamae/formbuilder";
import { Spinner } from "components/spinner/Spinner";
import { ApiResponse } from "@avamae/formbuilder/dist/FormBuilder";

interface InitialPayoutPageProps {
    setAmount: React.Dispatch<SetStateAction<AmountStateProps>>;
    setIsInitialPage: React.Dispatch<SetStateAction<boolean>>;
    amount: AmountStateProps;
}

const validationSchema = Yup.object({
    payout__Amount: Yup.number().min(1, "Amount must be greater than zero").required("Please provide an amount to withdraw"),
    payout__Currency: Yup.string().required("Please provide a currency"),
})

const initialValues = {
    payout__Amount: 0,
    payout__Currency: "",
}
type InitialPayoutFormProps = {
    payout__Amount: number;
    payout__Currency: string;
}

const InitialPayoutPage: React.FC<InitialPayoutPageProps> = ({ setAmount, setIsInitialPage, amount }) => {
    const handleSubmit = (FormikProps: InitialPayoutFormProps) => {
        console.log("handleSubmit", FormikProps)
        setAmount({
            ...amount,
            currency: FormikProps.payout__Currency,
            amount: FormikProps.payout__Amount && FormikProps.payout__Amount,
        });
        setIsInitialPage(false);
    }
    return (
        <div className="Content">
            <div className="SectionContainer">
                <div className="Section">
                    <p>Account ID: <span className="PayoutValue">1111</span></p>
                    <p>Current Balance: <span className="PayoutValue">$10,000</span></p>
                    <p>Available Balance: <span className="PayoutValue">$5,890</span></p>
                    <p>Payouts: <span className="PayoutValue">6</span></p>
                </div>
                <div className="Section">
                    <p>Customer: <span className="PayoutValue">1111</span></p>
                    <p>Language: <span className="PayoutValue">English</span></p>
                    <p>Currency: <span className="PayoutValue">USD</span></p>
                </div>
            </div>
            <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={validationSchema}
            >
                {formikProps => (
                    <Form>
                        <div className="InputContainer">
                            <div className="FormBoxContainer">
                                <FormBuilder
                                    loadingFallback={<Spinner />}
                                    data={data}
                                    additionalData={{ payout__Currency: options }}
                                />
                            </div>
                        </div>
                        <PayoutLimits minimum={100} maximum={5000} endText={true} />
                        <div className="SubmitContainer">
                            <button type="submit" className="SubmitBtn">Payout</button>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    )
}

export { InitialPayoutPage };

const options = [
    { value: "USD", label: "USD" }
]

const data: ApiResponse<any> = {
    id: 0,
    details: {},
    metadata: [
        {
            name: "payout__Currency",
            type: "singleselect",
            customType: null,
            label: "Currency",
            children: []
        },
        {
            name: "payout__Amount",
            type: "text",
            label: "Amount",
            children: []
        },
    ],
    status: "1",
    errors: []
}