import React from "react";
import { ThreeBounce } from "better-react-spinkit";

interface SpinnerProps {
  size?: number;
  color?: string;
  scaleStart?: number;
  scaleEnd?: number;
}

const Spinner: React.FC<SpinnerProps> = ({
  size = 15,
  color = "#067889",
}) => {
  return (
    <div className="Spinner">
      <ThreeBounce size={size} color={color} />
    </div>
  );
};

export { Spinner };
