import React from "react";
import { Deposit } from "components/pages/deposit/Deposit";
import { AmountStateProps, Payout } from "components/pages/payout/Payout";
import { NewCreditCardDeposit } from "components/depositComponents/newCreditCardDeposit/NewCreditCardDeposit";
import { BitcoinDeposit } from "components/depositComponents/bitcoinDeposit/BitcoinDeposit";
import { CryptoDeposit } from "components/depositComponents/cryptoDeposit/CryptoDeposit";
import { OtherDeposit } from "components/depositComponents/otherDeposit/OtherDeposit";
import { BitcoinPayout } from "components/payoutComponents/bitcoinPayout/BitcoinPayout";
import { CreditCardPayout } from "components/payoutComponents/creditCardPayout/CreditCardPayout";
import { OthersPayout } from "components/payoutComponents/othersPayout/OthersPayout";
import { CreditCardDeposit } from "components/depositComponents/creditCardDeposit/CreditCardDeposit";
import { useStore } from "reducers/Store";
import { NewCreditCardPayout } from "components/payoutComponents/newCreditCardPayout/NewCreditCardPayout";

type PageRouterProps = {
    method: string;
};

const PageRouter: React.FC<PageRouterProps> = ({ method }) => {
    switch (method) {
        case "deposit":
            return <Deposit />;
        case "payout":
            return <Payout />;
        default:
            return null;
    }
};

const DepositTabRouter = () => {
    const store = useStore();
    switch (store.tab) {
        case "credit card":
            return <CreditCardDeposit />;
        case "credit card new":
            return <NewCreditCardDeposit />;
        case "bitcoin":
            return <BitcoinDeposit />;
        case "crypto":
            return <CryptoDeposit />;
        case "other":
            return <OtherDeposit />;
        default:
            return null;
    }
};

interface PayoutTabRouterProps {
    amount: AmountStateProps;
}

const PayoutTabRouter: React.FC<PayoutTabRouterProps> = ({ amount }) => {
    const payoutAmount = amount.amount;
    const payoutCurrency = amount.currency;

    const store = useStore();
    switch (store.tab) {
        case "credit card":
            return (
                <CreditCardPayout
                    amount={payoutAmount}
                    currency={payoutCurrency}
                />
            );
        case "credit card new":
            return (
                <NewCreditCardPayout
                    amount={payoutAmount}
                    currency={payoutCurrency}
                />
            );
        case "bitcoin":
            return (
                <BitcoinPayout
                    amount={payoutAmount}
                    currency={payoutCurrency}
                />
            );
        case "other":
            return (
                <OthersPayout amount={payoutAmount} currency={payoutCurrency} />
            );
        default:
            return null;
    }
};

export { PageRouter, DepositTabRouter, PayoutTabRouter };
