import React from "react";


type DepositLimitsProps = {
    minimum: number;
    maximum: number;
}

const DepositLimits: React.FC<DepositLimitsProps> = ({ minimum, maximum }) => {
    return (
        <div className="DepositLimits">
            <p>Deposit Limits: Minimum {minimum} USD Maximum {maximum} USD</p>
        </div>
    )
}

export { DepositLimits }