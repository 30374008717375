import { endpoints } from "config";

import axios from "api";
import {
  EWalletPayoutData,
  EWalletPayoutDetails,
  PayoutPayload,
} from "components/pages/payout/models";

type Error = {
  code: string;
  type: string;
  parameterName: string | null;
  message: string;
};

type ApiReturn<T = any> = {
  response: T | null;
  errors: Error[] | null;
};

const payoutUrl = endpoints.payout.payout;

const postEWalletPayout = async (
  payload: PayoutPayload
): Promise<ApiReturn> => {
  try {
    const res = await axios.post(payoutUrl, payload);
    if (res.data.result && res.data.result.bSuccessful === true) {
      console.log("res:", res);
      return {
        response: res.data.result,
        errors: null,
      };
    } else {
      console.log("else res:", res);
      return {
        response: res.data.result && res.data.result.details,
        errors: res.data.errors,
      };
    }
  } catch (error) {
    return {
      response: null,
      errors: (error as any).response,
    };
  }
};

export const parseEWalletPayoutPayload = (
  payload: EWalletPayoutDetails
): PayoutPayload<EWalletPayoutData> => {
  console.log({ payload });
  const parsedDto: PayoutPayload<EWalletPayoutData> = {
    payoutData: {
      EWalletAddress: payload.payout__BtcAddress,
    },
    type: payload.payout__Type,
    currency: payload.payout__Currency,
    amount:
      payload.payout__Amount && parseInt(payload.payout__Amount.toString()),
    playerId: payload.payout__playerId,
    additionalData: {
      additionalProp1: {},
      additionalProp2: {},
      additionalProp3: {},
    },
  };
  return parsedDto;
};

export { postEWalletPayout };
