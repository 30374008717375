import React, { useState } from "react";
import { Formik, Form } from "formik";
import {
    FormBuilder,
    FormSingleSelectField,
    FormTextField,
} from "@avamae/formbuilder";
import { Spinner } from "components/spinner/Spinner";
import {
    ApiResponse,
    FieldRenderers,
} from "@avamae/formbuilder/dist/FormBuilder";
import * as Yup from "yup";
import { PayoutLimits } from "../payoutLimits/PayoutLimits";
import {
    getCardDetails,
    parseCCPayoutPayload,
    postCreditCardPayout,
} from "./CreditCardPayoutApi";
import { SubmitCCPayoutDetails, SubmitCCPayoutDto } from "./models";
import "react-datepicker/dist/react-datepicker.css";
import useMedia from "use-media";
import { errorHandling } from "components/helpers/formHelpers/ErrorHandling";
import { AmountStateProps } from "components/pages/payout/Payout";
import { FormRow } from "components/formRow/FormRow";
import {
    SuccessModal,
    SuccessProps,
} from "components/successModal/SuccessModal";
import { useStore } from "reducers/Store";

const cardCurrencyDropdown = [
    { value: "USD", label: "USD" },
    { value: "GBP", label: "GBP" },
    { value: "EUR", label: "EUR" },
];

const CreditCardPayout: React.FC<AmountStateProps> = ({ amount, currency }) => {
    const store = useStore();
    const [error, setError] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);
    const [existingCards, setExistingCards] = useState<any | null>(null);
    const [success, setSuccess] = useState<SuccessProps | null>(null);
    const isDesktop = useMedia({ minWidth: 850 });

    const initialValues = {
        card__Reference: "",
        card__CVV: null,
        card__Type: "",
        card__Currency: currency,
        card__PayoutAmount: amount,
        // card__bSameAddress: false,
        // card__BillingCountry: "",
        // card__BillingState: "",
        // card__BillingAddress: "",
        // card__BillingCity: "",
        // card__BillingZipCode: "",
        card__PayoutBonus: "",
        card__Existing: null,
    };
    React.useEffect(() => {
        (async () => {
            // If no playerId, early return
            if (!store.playerId) {
                return;
            }
            const result = await getCardDetails(store.playerId);
            if (result.response) {
                const existingCardsArray = result.response.map(r => {
                    return {
                        value: r,
                        label: r.nameOnCard + " - card ending " + r.endDigits,
                    };
                });
                console.log(existingCardsArray);
                setExistingCards(existingCardsArray);
            }
            if (result.errors && result.errors.length > 0) {
                console.log(result.errors);
                setError(errorHandling(result.errors[0].Code));
            }
        })();
    }, [store.playerId]);

    const handleSubmit = (FormikProps: SubmitCCPayoutDetails) => {
        setLoading(true);
        console.log("FormikProps: ", FormikProps);

        const payload: SubmitCCPayoutDto = {
            card__Reference:
                FormikProps.card__Existing &&
                FormikProps.card__Existing.reference,
            card__CVV:
                FormikProps.card__Existing && FormikProps.card__Existing.cvv,
            card__Type: FormikProps.card__Type,
            card__Currency: FormikProps.card__Currency,
            card__PayoutAmount: FormikProps.card__PayoutAmount,
            // card__bSameAddress: FormikProps.card__bSameAddress,
            // card__BillingCountry: FormikProps.card__BillingCountry,
            // card__BillingState: FormikProps.card__BillingState,
            // card__BillingAddress: FormikProps.card__BillingAddress,
            // card__BillingCity: FormikProps.card__BillingCity,
            // card__BillingZipCode: FormikProps.card__BillingZipCode,
            card__PayoutBonus: FormikProps.card__PayoutBonus,
        };

        (async () => {
            console.log({ payload });
            const result = await postCreditCardPayout(
                parseCCPayoutPayload(payload, store.playerId)
            );
            if (result.response) {
                console.log("credit card post was a success");
                setSuccess({ html: result.response.details });
                setLoading(false);
            }
            if (result.errors && result.errors.length > 0) {
                console.log(result.errors);
                setError(errorHandling(result.errors[0].Code));
                setLoading(false);
            }
        })();
    };

    const validationSchema = Yup.object({
        //     card__Reference: Yup.string().required('Please select an existing card'),
        //     card__CVV: Yup.number().nullable().required("CVV is required"),
        //     card__Type: Yup.string().required("Card type is required"),
        //     card__Currency: Yup.string().required("Currency is required"),
        // card__PayoutAmount: Yup.number().nullable().required("An amount to be payouted is required"),
        // card__BillingCountry: Yup.string().when("card__bSameAddress", { is: true, then: null, otherwise: Yup.string().required("Country is required") }),
        // card__BillingState: Yup.string().when("card__bSameAddress", { is: true, then: null, otherwise: Yup.string().required("Please choose a state") }),
        // card__BillingAddress: Yup.string().when("card__bSameAddress", { is: true, then: null, otherwise: Yup.string().required("An address is required") }),
        // card__BillingCity: Yup.string().when("card__bSameAddress", { is: true, then: null, otherwise: Yup.string().required("Please choose a city") }),
        // card__BillingZipCode: Yup.string().when("card__bSameAddress", { is: true, then: null, otherwise: Yup.string().required("Zip code is required") }),
        // card__PayoutBonus: Yup.string().required("Please select a bonus"),
    });

    const expiryFieldRenderers: FieldRenderers<any> = {
        card__ExpDate: (i, field) => (
            <div className="HalfWidthFields">
                <FormTextField
                    fieldName={"card__Existing.expiryMonth"}
                    label={"Expiry Month"}
                    readOnly
                />
                <FormTextField
                    fieldName={"card__Existing.expiryYear"}
                    label={"Expiry Year"}
                    readOnly
                />
            </div>
        ),
    };

    return (
        <>
            <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={validationSchema}
            >
                {formikProps => (
                    <Form>
                        <>
                            {isDesktop ? (
                                <>
                                    <div className="FormContainer">
                                        <div className="FormRow CreditCardSelect">
                                            {existingCards ? (
                                                <FormSingleSelectField<any>
                                                    fieldName="card__Existing"
                                                    label="Select an existing card"
                                                    options={existingCards}
                                                />
                                            ) : (
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        justifyContent:
                                                            "center",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <Spinner />
                                                </div>
                                            )}
                                        </div>
                                        <div className="FormRow FormRowHeaders">
                                            <h3>Card Details</h3>
                                        </div>
                                        <FormRow data={dataRowOne} readOnly />
                                        <FormRow
                                            data={dataRowTwo}
                                            overrideFormFieldRenderer={
                                                expiryFieldRenderers
                                            }
                                            readOnly
                                        />
                                        <FormRow
                                            data={dataRowThree}
                                            additionalData={{
                                                card__Currency: cardCurrencyDropdown,
                                            }}
                                            readOnly
                                        />
                                        <div className="Divider"></div>
                                    </div>
                                    <PayoutLimits
                                        minimum={45}
                                        maximum={1500}
                                        endText={false}
                                    />
                                    <div className="SubmitContainer">
                                        <button
                                            type="submit"
                                            className="SubmitBtn"
                                        >
                                            {loading ? (
                                                <Spinner color="white" />
                                            ) : (
                                                "Payout"
                                            )}
                                        </button>
                                    </div>
                                    {error && (
                                        <div className="ErrorMessage">
                                            {error}
                                        </div>
                                    )}
                                    {success && (
                                        <SuccessModal
                                            html={success.html}
                                            address={success.address}
                                            setSuccess={setSuccess}
                                            paymentType="credit card"
                                            type="payout"
                                        />
                                    )}
                                </>
                            ) : (
                                <>
                                    <div className="FormContainer">
                                        <div className="FormSectionMob">
                                            <div className="CreditCardSelect">
                                                {existingCards ? (
                                                    <FormSingleSelectField<any>
                                                        fieldName="card__Existing"
                                                        label="Select an existing card"
                                                        options={existingCards}
                                                    />
                                                ) : (
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            justifyContent:
                                                                "center",
                                                            alignItems:
                                                                "center",
                                                        }}
                                                    >
                                                        <Spinner />
                                                    </div>
                                                )}
                                            </div>
                                            <h3>Card Details</h3>
                                            <FormBuilder
                                                data={mobCardData}
                                                overrideFormFieldRenderer={
                                                    expiryFieldRenderers
                                                }
                                                loadingFallback={<Spinner />}
                                                hiddenFields={{
                                                    card__CVV: true,
                                                }}
                                                readOnly
                                            />
                                            <FormBuilder
                                                data={mobCardInputData}
                                                overrideFormFieldRenderer={
                                                    expiryFieldRenderers
                                                }
                                                loadingFallback={<Spinner />}
                                                additionalData={{
                                                    card__Currency: cardCurrencyDropdown,
                                                }}
                                                readOnly
                                            />
                                        </div>
                                        <PayoutLimits
                                            minimum={45}
                                            maximum={1500}
                                            endText={false}
                                        />
                                    </div>
                                    <div className="SubmitContainer">
                                        <button
                                            type="submit"
                                            className="SubmitBtn"
                                        >
                                            {loading ? (
                                                <Spinner color="white" />
                                            ) : (
                                                "Payout"
                                            )}
                                        </button>
                                    </div>
                                    {error && (
                                        <div className="ErrorMessage">
                                            {error}
                                        </div>
                                    )}
                                    {success && (
                                        <SuccessModal
                                            html={success.html}
                                            address={success.address}
                                            setSuccess={setSuccess}
                                            paymentType="Credit Card"
                                            type="Payout"
                                        />
                                    )}
                                </>
                            )}
                        </>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export { CreditCardPayout };

const dataRowOne: ApiResponse<any> = {
    id: 0,
    details: {},
    metadata: [
        {
            name: "card__Existing.nameOnCard",
            type: "text",
            customType: null,
            label: "Name on card",
            children: [],
        },
        {
            name: "card__Existing.endDigits",
            type: "text",
            customType: null,
            label: "Card end digits",
            children: [],
        },
    ],
    status: "1",
    errors: [],
};
const dataRowTwo: ApiResponse<any> = {
    id: 0,
    details: {},
    metadata: [
        {
            name: "card__Existing.cvv",
            type: "text",
            customType: null,
            label: "CVV",
            children: [],
        },
        {
            name: "card__ExpDate",
            type: "text",
            customType: null,
            label: "Expiry month",
            children: [],
        },
    ],
    status: "1",
    errors: [],
};
const dataRowThree: ApiResponse<any> = {
    id: 0,
    details: {},
    metadata: [
        {
            name: "card__PayoutAmount",
            type: "number",
            customType: null,
            label: "Amount",
            children: [],
        },
        {
            name: "card__Currency",
            type: "text",
            customType: null,
            label: "Currency",
            children: [],
        },
    ],
    status: "1",
    errors: [],
};

const mobCardData: ApiResponse = {
    id: 0,
    details: {},
    metadata: [
        {
            name: "card__Existing.nameOnCard",
            type: "text",
            customType: null,
            label: "Name on card",
            children: [],
        },
        {
            name: "card__Existing.endDigits",
            type: "text",
            customType: null,
            label: "Card end digits",
            children: [],
        },
        {
            name: "card__ExpDate",
            type: "text",
            customType: null,
            label: "Expiry month",
            children: [],
        },
        {
            name: "card__Existing.cvv",
            type: "text",
            customType: null,
            label: "CVV",
            children: [],
        },
    ],
    status: "1",
    errors: [],
};
const mobCardInputData: ApiResponse = {
    id: 0,
    details: {},
    metadata: [
        {
            name: "card__PayoutAmount",
            type: "number",
            customType: null,
            label: "Amount",
            children: [],
        },
        {
            name: "card__Currency",
            type: "text",
            customType: null,
            label: "Currency",
            children: [],
        },
    ],
    status: "1",
    errors: [],
};

// const mobBillingData: ApiResponse = {
//     id: 0,
//     details: {},
//     metadata: [
//         {
//             name: "card__bSameAddress",
//             type: "custom",
//             customType: null,
//             label: "Tick this box if your home address is the same as billing address",
//             children: []
//         },
//         // {
//         //     name: "card__BillingCountry",
//         //     type: "singleselect",
//         //     label: "Address",
//         //     children: []
//         // },
//         // {
//         //     name: "card__BillingState",
//         //     type: "singleselect",
//         //     label: "State",
//         //     children: []
//         // },
//         {
//             name: "card__Address",
//             type: "text",
//             customType: null,
//             label: "Address",
//             children: []
//         },
//         {
//             name: "card__City",
//             type: "text",
//             customType: null,
//             label: "City",
//             children: []
//         },
//         {
//             name: "card__ZipCode",
//             type: "text",
//             customType: null,
//             label: "ZIP code",
//             children: []
//         },
//     ],
//     status: "1",
//     errors: []
// }
