import { FormBuilder } from "@avamae/formbuilder"
import { ApiResponse } from "@avamae/formbuilder/dist/FormBuilder"
import { errorHandling } from "components/helpers/formHelpers/ErrorHandling"
import { AmountStateProps } from "components/pages/payout/Payout"
import { Spinner } from "components/spinner/Spinner"
import { Form, Formik } from "formik"
import React, { useState } from "react"
import { useStore } from "reducers/Store"
import { PayoutLimits } from "../payoutLimits/PayoutLimits"
import { SubmitOtherPayoutDto } from "./models"
import { parseOtherPayoutPayload, postOtherPayout } from "./OthersPayoutApi"



const OthersPayout: React.FC<AmountStateProps> = ({ amount, currency }) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [success, setSuccess] = useState<{ html: string | null }>({ html: null });
    const [error, setError] = useState<string | null>(null);

    const store = useStore();

    const initialValues = {
        payout__Amount: amount,
        payout__Currency: currency,
    }

    const handleSubmit = (FormikProps: SubmitOtherPayoutDto) => {
        setLoading(true)
        console.log("FormikProps: ", FormikProps)

        const payload: SubmitOtherPayoutDto = {
            payout__Amount: FormikProps.payout__Amount,
            payout__Currency: FormikProps.payout__Currency,
        };

        (async () => {
            console.log({ payload })
            const result = await postOtherPayout(parseOtherPayoutPayload(payload, store.playerId))
            if (result.response) {
                console.log("credit card post was a success")
                setSuccess({ html: result.response.details })
                setLoading(false);

            }
            if (result.errors && result.errors.length > 0) {
                console.log(result.errors)
                setError(errorHandling(result.errors[0].code))
                setLoading(false)

            }
        })()
    }

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
        >

            <Form>
                <div className="PayoutContainer">
                    <h3>Payout using MoneyGram</h3>
                    <div className="PayoutMoneyGramContainer">

                        <FormBuilder
                            loadingFallback={<Spinner />}
                            data={formData}
                            readOnlyFields={{ payout__FirstName: true, payout__LastName: true, payout__SecondLastName: true, payout__Country: true, payout__StateProv: true, payout__City: true, payout__Address: true, payout__ZipCode: true, payout__PhoneNumber: true, payout__Amount: true }}
                        />
                    </div>

                </div>
                <PayoutLimits minimum={100} maximum={390} endText={false} />
                <div className="SubmitContainer">
                    <button className="SubmitBtn" type="submit">Submit</button>
                </div>
            </Form>
        </Formik>
    )
}

export { OthersPayout };

const formData: ApiResponse = {
    id: 0,
    details: {},
    metadata: [
        {
            name: "payout__FirstName",
            type: "text",
            customType: null,
            label: "First name",
            children: []
        },
        {
            name: "payout__LastName",
            type: "text",
            customType: null,
            label: "Last name",
            children: []
        },
        {
            name: "payout__SecondLastName",
            type: "text",
            customType: null,
            label: "Second Last name",
            children: []
        },
        {
            name: "payout__Country",
            type: "text",
            customType: null,
            label: "Country",
            children: []
        },
        {
            name: "payout__StateProv",
            type: "text",
            customType: null,
            label: "State/Prov",
            children: []
        },
        {
            name: "payout__City",
            type: "text",
            customType: null,
            label: "City",
            children: []
        },
        {
            name: "payout__Address",
            type: "text",
            customType: null,
            label: "Address",
            children: []
        },
        {
            name: "payout__ZipCode",
            type: "text",
            customType: null,
            label: "ZIP code",
            children: []
        },
        {
            name: "payout__PhoneNumber",
            type: "text",
            customType: null,
            label: "Phone number",
            children: []
        },
        {
            name: "payout__Amount",
            type: "text",
            customType: null,
            label: "Amount",
            children: []
        },
        {
            name: "payout__Comments",
            type: "textarea",
            customType: null,
            label: "Comments",
            children: []
        },

    ],
    status: "1",
    errors: []
}