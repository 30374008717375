import { FormBuilder } from "@avamae/formbuilder";
import { FormBuilderProps } from "@avamae/formbuilder/dist/FormBuilder";
import { Spinner } from "components/spinner/Spinner";
import React from "react";

type FormRowProps = Omit<FormBuilderProps<any>, "loadingFallback">;

const FormRow = (props: FormRowProps) => {
    return (
        <div className="FormRow">
            <FormBuilder
                {...props}
                loadingFallback={<Spinner />}
            />
        </div>
    )
}

export { FormRow };