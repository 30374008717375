import { produce } from "immer";
import { Store } from "reducers/rootReducer";
import { of, from, Observable } from "rxjs";
import { AxiosRequestConfig, AxiosError } from "axios";
import { endpoints } from "config";
import { Scopes, loadTokenResource, saveTokenResource } from "helpers/auth";
import api from "api";
import {
  map,
  tap,
  switchMap,
  catchError,
  ignoreElements,
} from "rxjs/operators";
import { combineEpics, ofType, ActionsObservable } from "redux-observable";
import { createSelector } from "reselect";
import { addSeconds } from "date-fns";

export type SignInAttempt = {
  username: string;
  password: string;
};

type Error = {
  code: string | null;
  type: string | null;
  parameterName: string | null;
  message: string;
};

type SignInError = {
  errors: Error[];
};

type SignInOkay = {
  result: {
    accessToken: string;
  };
};

type SignInResponse = SignInOkay | SignInError;

const isResponseOkay = (r: SignInResponse): r is SignInOkay =>
  (r as SignInOkay).result.accessToken ? true : false;

/* STATE */
type AuthStatus = "signed_out" | "signed_in" | "pending";
type State = {
  appVisible: boolean;
  authStatus: AuthStatus;
  message: string;
};

const initialState: State = {
  authStatus: "signed_out",
  appVisible: false,
  message: "",
};

/* ACTIONS */

const SIGN_OUT = "app/auth/SIGN_OUT";
export const SIGN_IN = "app/auth/SIGN_IN";
const SIGN_IN_OKAY = "app/auth/SIGN_IN_OKAY";
const SIGN_IN_ERROR = "app/auth/SIGN_IN_ERROR";
const SHOW_APP = "app/auth/SHOW_APP";
const CHECK_SIGNED_IN = "app/auth/CHECK_SIGNED_IN";

type Action =
  | { type: typeof SIGN_IN; payload: SignInAttempt }
  | { type: typeof SIGN_OUT; message: string; signOutMarker: boolean }
  | { type: typeof CHECK_SIGNED_IN }
  | { type: typeof SHOW_APP; payload: boolean }
  | { type: typeof SIGN_IN_OKAY; payload: SignInOkay }
  | { type: typeof SIGN_IN_ERROR; payload: SignInError };

/* REDUCER */

export default function reducer(state = initialState, action: Action): State {
  return produce(state, (draft) => {
    switch (action.type) {
      case SHOW_APP:
        draft.appVisible = true;
        // Is the user already signed in?
        if (action.payload === true) draft.authStatus = "signed_in";
        break;
      case SIGN_OUT:
        draft.authStatus = "signed_out";
        draft.message = action.message;
        break;
      case SIGN_IN:
        draft.authStatus = "pending";
        draft.message = "";
        break;
      case SIGN_IN_OKAY:
        draft.authStatus = "signed_in";
        break;
      // case SIGN_IN_ERROR:
      //   draft.authStatus = "signed_out";
      //   draft.message = action.payload.errors[0].message;
      //   break;
    }
  });
}

/* EPICS */

function makeRequest<T>(config: AxiosRequestConfig) {
  return from(api.request<T>(config));
}

function saveResponse(r: SignInResponse) {
  console.log({ r });
  if (isResponseOkay(r)) {
    const accessToken = localStorage.getItem('AT') ? localStorage.getItem('AT') : '';
    const { refreshToken, expiresIn, scopes } = {
      refreshToken: "REFRESHTOKENTEST",
      expiresIn: "9999999",
      scopes: [],
    };
    const refreshTokenExpires = addSeconds(new Date(), parseInt(expiresIn, 10));
    console.log("responseOkay");
    saveTokenResource({
      accessToken: accessToken ?? '',
      refreshToken: refreshToken ?? "ASDASD",
      refreshTokenExpires: refreshTokenExpires ?? new Date("2099-10-10"),
      scopes: scopes ?? [],
    });
  }
}

function checkResponse(r: SignInResponse) {
  if (isResponseOkay(r)) return signInOkay(r);
  return signInError(r);
}

const parseApiErrorMessageCode = (code: string): string => {
  switch (code) {
    case "Invalid_User":
      return "Invalid";
    default:
      return "Oops, something went wrong. Please try again later.";
  }
};

/** Attempt to sign in and handle response, cancel if we sign out while a request pending. */
const signInEpic = (action$: ActionsObservable<any>): Observable<Action> =>
  action$.pipe(
    ofType(SIGN_IN),
    switchMap((attempt) =>
      makeRequest<SignInResponse>({
        url: endpoints.auth.login,
        data: attempt.payload,
        method: "post",
      }).pipe(
        tap((res) => saveResponse(res.data)),
        map((res) => checkResponse(res.data)),
        catchError((err: AxiosError) => {
          console.log("err", err);
          const errorMessage = parseApiErrorMessageCode(
            err.response?.data.errors &&
              err.response.data.errors.length &&
              err.response.data.errors.length > 0
              ? err.response?.data.errors[0].messageCode
              : ""
          );
          return of(
            signInError({
              errors: [
                {
                  code: null,
                  type: null,
                  parameterName: null,
                  message: `${errorMessage}`,
                },
              ],
            })
          );
        })
        //takeUntil(action$.pipe(ofType(SIGN_OUT, SIGN_IN_ERROR)))
      )
    )
  );

/** Clear the local storage on sign out, so we don't appear logged in on refresh. */
const signOutEpic = (action$: ActionsObservable<any>) =>
  action$.pipe(
    ofType(SIGN_OUT),
    tap((action) => {
      window && window.localStorage.clear();
      if (action.signOutMarker) {
        window && window.localStorage.setItem("signOutAlert", "true");
      }
    }),
    ignoreElements()
  );

const checkSignedInEpic = (action$: ActionsObservable<any>) =>
  action$.pipe(
    ofType(CHECK_SIGNED_IN),
    map(loadTokenResource),
    map((r) => r !== null),
    map(showApp)
  );

export const authEpic = combineEpics(
  signInEpic,
  signOutEpic,
  checkSignedInEpic
);

/* ACTION CREATORS */

export const signIn = (payload: SignInAttempt): Action => ({
  type: SIGN_IN,
  payload,
});

export const signOut = (message = "", marker = true): Action => ({
  type: SIGN_OUT,
  message,
  signOutMarker: marker,
});

export const checkSignedIn = (): Action => ({
  type: CHECK_SIGNED_IN,
});

const signInOkay = (payload: SignInOkay): Action => ({
  type: SIGN_IN_OKAY,
  payload,
});

const signInError = (payload: SignInError): Action => ({
  type: SIGN_IN_ERROR,
  payload,
});

const showApp = (payload: boolean): Action => ({
  type: SHOW_APP,
  payload,
});

/* SELECTORS */

export const selectAuthStatus = (app: Store) => app.auth.authStatus;
export const selectSignedIn = createSelector(
  selectAuthStatus,
  (status) => status === "signed_in"
);
export const selectAuthMessage = (app: Store) => app.auth.message;
export const selectAppVisible = (app: Store) => app.auth.appVisible;
