import { FormBuilder } from '@avamae/formbuilder';
import { ApiResponse } from '@avamae/formbuilder/dist/FormBuilder';
import { Spinner } from 'components/spinner/Spinner';
import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import { Bonuses, DepositBonuses } from '../depositBonuses/DepositBonuses';
import { DepositLimits } from '../depositLimits/DepositLimits';
import BitcoinLogo from '../../../assets/Img_Logo_Bitcoin.png';
import BitcoinCashLogo from '../../../assets/Img_Logo_BitcoinCash.png';
import BitcoinGoldLogo from '../../../assets/Img_Logo_BitcoinGold.png';
import DashLogo from '../../../assets/Img_Logo_Dash.png';
import EthereumLogo from '../../../assets/Img_Logo_Ethereum.png';
import EthereumClassicLogo from '../../../assets/Img_Logo_EthereumClassic.png';
import LitecoinLogo from '../../../assets/Img_Logo_Litecoin.png';
import QTUMLogo from '../../../assets/Img_Logo_QTUM.png';
import RippleLogo from '../../../assets/Img_Logo_Ripple.png';
import { EWalletDepositDetails, EWalletDepositProps } from './models';
import { parseEWalletDepositPayload, postEWalletDeposit } from './EWalletDepositApi';
import { errorHandling } from 'components/helpers/formHelpers/ErrorHandling';
import { SuccessModal, SuccessProps } from 'components/successModal/SuccessModal';
import { useStore } from 'reducers/Store';
import * as Yup from 'yup';

const initialValues = {
    eWallet__Currency: "",
    eWallet__Amount: null,
    eWallet__Bonus: null,
}

const bonuses: Bonuses[] = [
    { label: "UFC123", description: "123% Sports bonus (max bonus $1,500) (20x rollover)" },
    { label: "NBA123", description: "123% Sports bonus (max bonus $1,500) (20x rollover)" },
    { label: "GOLF123", description: "123% Sports bonus (max bonus $1,000) (20x rollover)" },
    { label: "CAGEWARRIORS", description: "100% Cash bonus (max bonus $1,000) (20x rollover)" },
    { label: "CASINO250", description: "250% Casino bonus (max bonus $10,000) (80x rollover) (bonus play in rebate casino only)" },
    { label: "RE-UP30", description: "30% Sports cash bonus (max bonus $2,500) (10x rollover) (BTC depositors only)" },
    { label: "NO BONUS", description: "1x rollover required of the deposit amount prior to requesting payout" },
]

const EWalletDeposit: React.FC<EWalletDepositProps> = ({ actions, crypto, minimum, maximum }) => {
    const store = useStore();
    const [error, setError] = useState<string>("");
    const [success, setSuccess] = useState<SuccessProps | null>(null);
    const [loading, setLoading] = useState<boolean>(false);

    const cryptoLogo = () => {
        switch (crypto) {
            case "bitcoin":
                return BitcoinLogo;
            case "litecoin":
                return LitecoinLogo;
            case "ethereum":
                return EthereumLogo;
            case "dash":
                return DashLogo;
            case "btc cash":
                return BitcoinCashLogo;
            case "ripple":
                return RippleLogo;
            case "qtum":
                return QTUMLogo;
            case "eth classic":
                return EthereumClassicLogo;
            case "btc gold":
                return BitcoinGoldLogo;
            default:
                return '';
        }
    }

    const validationSchema = Yup.object({
        eWallet__Currency: Yup.string().required('Please select a currency'),
        eWallet__Amount: Yup.string().required('Please provide a deposit amount'),
        eWallet__Bonus: Yup.string(),
    })

    const handleSubmit = (values: EWalletDepositDetails) => {
        const payload: EWalletDepositDetails = {
            eWallet__Currency: values.eWallet__Currency,
            eWallet__Amount: values.eWallet__Amount,
            eWallet__Bonus: values.eWallet__Bonus,
        };

        (async () => {
            setLoading(true)
            console.log({ payload })
            const result = await postEWalletDeposit(parseEWalletDepositPayload(payload, store.playerId))
            if (result.response) {
                console.log({ "result.response": result.response })
                setSuccess({ address: result.response.details.address, html: result.response.details.embeddedHtml })
            }
            if (result.errors && result.errors.length > 0) {
                console.log(result.errors)
                setError(errorHandling(result.errors[0].code))
            }
        })()
        setLoading(false)
    }

    return (
        <>
            <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={validationSchema}
            >
                <Form>
                    <div className="EWalletDeposit">
                        <div className="EWalletHeader">
                            <h3>Deposit using {crypto}</h3>
                            {crypto !== "bitcoin" &&
                                <button className="BackBtn" onClick={actions?.clearSelected}>back</button>
                            }
                        </div>
                        <div className="LogoContainer">
                            <img src={cryptoLogo()} alt="crypto-logo"></img>
                        </div>
                        <div className="FormBoxContainer">
                            <FormBuilder
                                loadingFallback={<Spinner />}
                                data={formData}
                                additionalData={{ eWallet__Currency: [{ value: "USD", label: "USD" }] }}
                            />
                        </div>
                    </div>
                    <DepositLimits minimum={minimum} maximum={maximum} />
                    <DepositBonuses bonuses={bonuses} bonusField={'eWallet__Bonus'} />
                    <div className="SubmitContainer">
                        <button type="submit" className="SubmitBtn">{loading ? <Spinner color="white" /> : 'Deposit'}</button>
                    </div>
                    {error &&
                        <div className="ErrorMessage">
                            {error}
                        </div>
                    }
                </Form>
            </Formik>
            {success &&
                <SuccessModal html={success.html} address={success.address} setSuccess={setSuccess} paymentType="eWallet" type="deposit" />
            }
        </>
    )
}

export { EWalletDeposit };

const formData: ApiResponse = {
    id: 0,
    details: {},
    metadata: [
        {
            name: "eWallet__Currency",
            type: "singleselect",
            customType: null,
            label: "Currency",
            children: []
        },
        {
            name: "eWallet__Amount",
            type: "number",
            customType: null,
            label: "Amount",
            children: []
        },

    ],
    status: "1",
    errors: []
}