import React, { useState } from 'react';
import { FormBuilder } from '@avamae/formbuilder';
import { ApiResponse } from '@avamae/formbuilder/dist/FormBuilder';
import { Spinner } from 'components/spinner/Spinner';
import { Form, Formik } from 'formik';
import { Bonuses, DepositBonuses } from '../depositBonuses/DepositBonuses';
import { DepositLimits } from '../depositLimits/DepositLimits';
import MoneyGramLogo from '../../../assets/Img_Logo_MoneyGram.png';
import { useMedia } from 'use-media';
import { MoneyGramConfirmDeposit, MoneyGramDepositDetails, MoneyGramDepositPayload, MoneyGramStartDeposit } from './models';
import { postMoneyGramDeposit } from './MoneyGramDepositApi';
import { errorHandling } from 'components/helpers/formHelpers/ErrorHandling';
import { SuccessModal, SuccessProps } from 'components/successModal/SuccessModal';
import { useStore } from 'reducers/Store';
import * as Yup from 'yup'

interface MoneyGramDepositProps {
    minimum: number;
    maximum: number;
}

const initialValues = {
    moneyGram__Currency: "",
    moneyGram__DepositAmount: null,
    moneyGram__FullName: "",
    moneyGram__TransactionID: null,
    moneyGram__ControlNumber: null,
    moneyGram__Bonus: null,
}

const bonuses: Bonuses[] = [
    { label: "UFC123", description: "123% Sports bonus (max bonus $1,500) (20x rollover)" },
    { label: "NBA123", description: "123% Sports bonus (max bonus $1,500) (20x rollover)" },
    { label: "GOLF123", description: "123% Sports bonus (max bonus $1,000) (20x rollover)" },
    { label: "CAGEWARRIORS", description: "100% Cash bonus (max bonus $1,000) (20x rollover)" },
    { label: "CASINO250", description: "250% Casino bonus (max bonus $10,000) (80x rollover) (bonus play in rebate casino only)" },
    { label: "RE-UP15", description: "15% Sports cash bonus (max bonus $2,500) (6x rollover)" },
    { label: "NO BONUS", description: "1x rollover required of the deposit amount prior to requesting payout" },
]

const MoneyGramDeposit: React.FC<MoneyGramDepositProps> = ({ minimum, maximum }) => {
    const store = useStore();
    const [step, setStep] = useState<number>(1);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>("");
    const [success, setSuccess] = useState<SuccessProps | null>(null);
    const handleStep = (s: number) => () => {
        setStep(s);
        setError("");
        setSuccess(null);
        window.scrollTo(0, 0)
    }
    const isWidth = useMedia({ minWidth: 300 });

    const handleSubmit = (s: number) => (values: MoneyGramDepositDetails) => {
        if (s === 1) {
            const payload: MoneyGramDepositPayload<MoneyGramStartDeposit> = {
                depositData: {
                    FullName: values.moneyGram__FullName
                },
                type: "MoneyGram",
                amount: values.moneyGram__DepositAmount,
                currency: values.moneyGram__Currency,
                playerId: store.playerId,
            };
            (async () => {
                setLoading(true)
                console.log({ payload })
                const result = await postMoneyGramDeposit(payload)
                console.log({ result })
                if (result.response) {
                    console.log("moneygram step 1 post was a success")
                    if (result.bSuccessful && result.bSuccessful === true) {
                        setSuccess({ html: result.response.details.embeddedHtml })
                    } else {
                        setError('An error has occurred. Please try again later.')
                    }
                }
                if (result.errors && result.errors.length > 0) {
                    console.log({ "result.errors": result.errors })
                    if (result.errors[0].code) {
                        setError(errorHandling(result.errors[0].code))
                    }
                    setLoading(false)
                }
            })()
        }
        if (s === 2) {
            const payload: MoneyGramDepositPayload<MoneyGramConfirmDeposit> = {
                depositData: {
                    TransactionID: values.moneyGram__TransactionID,
                    ControlNumber: values.moneyGram__ControlNumber,
                },
                type: "MoneyGramConfirm",
                amount: values.moneyGram__DepositAmount,
                currency: values.moneyGram__Currency,
                playerId: store.playerId,
                additionalData: {
                    BonusCode: values.moneyGram__Bonus,
                }
            };
            (async () => {
                setLoading(true)
                console.log({ payload })
                const result = await postMoneyGramDeposit(payload)
                console.log({ result })
                if (result.response) {
                    console.log("moneygram step 2 post was a success")
                    // setSuccess({ html: result.response.details.embeddedHtml })
                }
                if (result.errors && result.errors.length > 0) {
                    console.log({ "result.errors": result.errors })
                    if (result.errors[0].code) {
                        setError(errorHandling(result.errors[0].code))
                    }
                    setLoading(false)
                }
            })()
        }
    }

    const validationSchema = Yup.object({
        moneyGram__Currency: step === 1 ? Yup.string().required('Please proide a currency') : Yup.string(),
        moneyGram__DepositAmount: step === 1 ? Yup.number().required('Please proide a deposit amount') : Yup.number(),
        moneyGram__FullName: step === 1 ? Yup.string().required('Please provide your full name') : Yup.string(),
        moneyGram__TransactionID: step === 2 ? Yup.number().required('Please provide a transaction ID') : Yup.number(),
        moneyGram__ControlNumber: step === 2 ? Yup.number().required('Please provide a control number') : Yup.number(),
        moneyGram__Bonus: Yup.string(),
    });

    return (
        <>
            <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit(step)}
                validationSchema={validationSchema}
            >
                <Form>

                    <div className="EWalletDeposit">
                        <div className="EWalletHeader">
                            <h3>Deposit using MoneyGram</h3>
                            {/* <button className="BackBtn">back</button> */}

                        </div>
                        <div className="LogoContainer">
                            <img src={MoneyGramLogo} alt="MoneyGram Logo" />
                        </div>
                        <div className="MoneyGramControls">
                            <div className="Controls">
                                <button type="button" className="Btn BtnGreen StepBtn" onClick={handleStep(1)} style={{ marginRight: isWidth ? "1rem" : "0rem" }}>Step 1</button>
                                <button type="button" className="Btn BtnRed StepBtn" onClick={handleStep(2)} style={{ marginLeft: isWidth ? "1rem" : "0rem" }}>Step 2</button>
                            </div>
                            <p>{step === 1 ? "Already have your control number? Please click Step 2." : "If you need instructions go to Step 1"}</p>
                        </div>
                    </div>
                    {step === 1 &&
                        <>
                            <div className="InputContainer">
                                <div className="FormBoxContainer">
                                    <FormBuilder
                                        loadingFallback={<Spinner />}
                                        data={formData}
                                        additionalData={{ moneyGram__Currency: [{ value: "USD", label: "USD" }] }}
                                    />
                                </div>
                            </div>
                            <DepositLimits minimum={minimum} maximum={maximum} />
                            <DepositBonuses bonuses={bonuses} bonusField={'moneyGram__Bonus'} />
                            <div className="MoneyGramInfo">
                                <p><span>Customer Information: </span>Must match the sender information with the money transfer agency:</p>
                                <FormBuilder
                                    loadingFallback={<Spinner />}
                                    data={MoneyGramStep1Data}
                                    readOnlyFields={{ moneyGram__Country: true, moneyGram__StateProv: true, moneyGram__City: true, moneyGram__Phone: true }}
                                />
                                <p>Please note your person to person instructions will be valid for next 72 hours, after this time you will have to request new ones.</p>
                                <p>To receive your personalized money transfer instructions, click GET INSTRUCTIONS button.</p>
                                <p>If you are interested in sending more than 500, please contact customer support.</p>
                            </div>
                            <div className="SubmitContainer">
                                <button className="SubmitBtn" type="submit">Get Instructions</button>
                            </div>
                            <p className="FinalNote">If you need help confirming your money transfer transaction, please contact Customer Services and provide your Account ID, Trace ID and Control Number.</p>
                        </>
                    }
                    {step === 2 &&
                        <>
                            <div className="InputContainer">
                                <div className="FormBoxContainer">
                                    <FormBuilder
                                        loadingFallback={<Spinner />}
                                        data={MoneyGramStep2Data}
                                    />
                                </div>
                            </div>
                            <div className="SubmitContainer">
                                <button type="submit" className="SubmitBtn">Next</button>
                            </div>
                            <p className="FinalNote">If you need help confirming your money transfer transaction, please contact Customer Services and provide your Account ID, Trace ID and Control Number.</p>
                        </>
                    }
                    {error &&
                        <div className="ErrorMessage">
                            {error}
                        </div>
                    }
                    {success &&
                        <SuccessModal html={success.html} setSuccess={setSuccess} paymentType="MoneyGram" type="deposit" />
                    }
                </Form>
            </Formik>
        </>
    )
}

export { MoneyGramDeposit };

const formData: ApiResponse = {
    id: 0,
    details: {},
    metadata: [
        {
            name: "moneyGram__Currency",
            type: "singleselect",
            customType: null,
            label: "Currency",
            children: []
        },
        {
            name: "moneyGram__DepositAmount",
            type: "number",
            customType: null,
            label: "Amount",
            children: []
        },

    ],
    status: "1",
    errors: []
}
const MoneyGramStep1Data: ApiResponse = {
    id: 0,
    details: {},
    metadata: [
        {
            name: "moneyGram__FullName",
            type: "text",
            customType: null,
            label: "Full name",
            children: []
        },


    ],
    status: "1",
    errors: []
}
const MoneyGramStep2Data: ApiResponse = {
    id: 0,
    details: {},
    metadata: [
        {
            name: "moneyGram__TransactionID",
            type: "number",
            customType: null,
            label: "Transaction ID",
            children: []
        },
        {
            name: "moneyGram__ControlNumber",
            type: "text",
            customType: null,
            label: "Control Number",
            children: []
        },

    ],
    status: "1",
    errors: []
}