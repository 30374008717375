import { endpoints } from "config";
import axios from "api";
import { AxiosRequestConfig } from "axios";
import { OtherPayoutPayload, SubmitOtherPayoutDto } from "./models";

type Error = {
    code: string;
    message: string;
    parameterName: string | null;
    type: string;
};

type ApiReturn<T = any> = {
    response: T | null;
    errors: Error[] | null;
};

const payoutUrl = endpoints.payout.payout;

export const postOtherPayout = async (
    payload: OtherPayoutPayload
): Promise<ApiReturn> => {
    try {
        const res = await axios.post(payoutUrl, payload);
        if (res.data.result && res.data.result.bSuccessful === true) {
            console.log("res:", res);
            return {
                response: res.data.result,
                errors: null,
            };
        } else {
            console.log("else res:", res);
            return {
                response: res.data.result && res.data.result.details,
                errors: res.data.errors,
            };
        }
    } catch (error) {
        return {
            response: null,
            errors: (error as any).response.data.errors,
        };
    }
};

export const parseOtherPayoutPayload = (
    payload: SubmitOtherPayoutDto,
    playerId: string
): OtherPayoutPayload => {
    const parsedDto: OtherPayoutPayload = {
        payoutData: {
            valueKind: undefined,
        },
        type: "Moneygram",
        amount: payload.payout__Amount,
        currency: payload.payout__Currency,
        playerId: playerId,
    };
    return parsedDto;
};
