export const isProduction = process.env.REACT_APP_BUILD_TYPE === "production";

export const BASE_URL = isProduction
  ? "https://api.payment-gateway.solutions"
  : "https://quantumsolutionspaymentgateway-api.dev.avamae.co.uk";

export const endpoints = {
  auth: {
    login: "/api/v1/clientrole/authmodule/login",
    refreshAccessToken: "",
    requestpasswordreset: "",
    resetpassword: "",
    initialisepassword: "",
  },
  deposit: {
    deposit: "/api/v1/clientrole/depositmodule/deposit",
    getCreditCardDetails:
      "/api/v1/clientrole/depositmodule/getcreditcarddetails",
    deleteCreditCard: "/api/v1/clientrole/depositmodule/deletecreditcard",
  },
  info: {
    info: "/api/v1/clientrole/infomodule/info",
  },
  payout: {
    payout: "/api/v1/clientrole/payoutmodule/payout",
  },
  registration: {
    registerPlayer: "/api/v1/clientrole/registrationmodule/registerplayer",
    updatePlayerRegistration:
      "/api/v1/clientrole/registrationmodule/updateplayerregistration",
    checkPlayerRegistration:
      "/api/v1/clientrole/registrationmodule/checkplayerregistration",
  },
};
