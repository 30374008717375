import React from "react";

type BonusProps = {
    label: string;
    description: string;
    onClick: () => void;
}

const Bonus: React.FC<BonusProps> = ({ onClick, label, description }) => {
    return (
        <div className="Bonus FormBox FormSideBySide" onClick={onClick}>
            <label className="container">{label}
                <input type="radio" name="bonuses"></input>
                <span className="checkmark"></span>
                <p>{description}</p>
            </label>
        </div>
    )
}

export { Bonus };