export enum Type {
  "changeTab" = "CHANGE_CURRENT_TAB",
  "changePage" = "CHANGE_PAGE",
  "storePlayerId" = "STORE_PLAYER_ID",
  "storeViewModel" = "STORE_VIEW_MODEL",
}

export interface State {
  tab: string;
  path: string;
  playerId: string;
  viewModel: ViewModel[];
}

export interface AuthPayload {
  playerId: string | null;
  token: string | null;
}

export interface ViewModel {
  Code: string;
  DepositTypes: string;
  Description: string;
  MaxDeposit: number;
  MinDepositRequired: number;
  Name: string;
  OrderNumber: number;
  RequiredPlaythrough: number;
}

export type Action =
  | {
      type: Type.changeTab;
      payload: string;
    }
  | {
      type: Type.changePage;
      payload: string;
    }
  | {
      type: Type.storePlayerId;
      payload: string;
    }
  | {
      type: Type.storeViewModel;
      payload: ViewModel[];
    };
