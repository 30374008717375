import { endpoints } from "config";
import { CreditCardPayoutPayload, SubmitCCPayoutDto } from "./models";
import axios from "api";
import { AxiosRequestConfig } from "axios";

type Error = {
    Code: string;
    Message: string;
    ParameterName: string | null;
    Type: string;
};

type ApiReturn<T = any> = {
    response: T | null;
    errors: Error[] | null;
};

const postCreditCardPayout = async (
    payload: CreditCardPayoutPayload
): Promise<ApiReturn> => {
    try {
        const res = await axios.post(endpoints.payout.payout, payload);
        if (res.data.Result && res.data.Result.bSuccessful === true) {
            console.log("res:", res);
            return {
                response: res.data.Result,
                errors: null,
            };
        } else {
            console.log("else res:", res);
            return {
                response: res.data.Result && res.data.result.details,
                errors: res.data.Errors,
            };
        }
    } catch (error) {
        return {
            response: null,
            errors: (error as any).response.data.Errors,
        };
    }
};

export const parseCCPayoutPayload = (
    payload: SubmitCCPayoutDto,
    playerId: string
): CreditCardPayoutPayload => {
    const parsedDto: CreditCardPayoutPayload = {
        type: "CreditCard",
        amount: 100,
        currency: "USD",
        payoutData: {
            CreditCardReference: payload.card__Reference,
            CreditCardCvv: payload.card__CVV
                ? payload.card__CVV.toString()
                : null,
        },
        playerId: playerId,
    };
    return parsedDto;
};

const getCardDetails = async (playersId: string): Promise<ApiReturn<any[]>> => {
    try {
        const config: AxiosRequestConfig = {
            params: {
                playersId,
            },
        };
        const res = await axios.get(
            endpoints.deposit.getCreditCardDetails,
            config
        );
        if (res.data.status === `1`) {
            return {
                response: res.data.result.details,
                errors: null,
            };
        } else {
            return {
                response: res.data.result.details,
                errors: res.data.errors,
            };
        }
    } catch (error) {
        return {
            response: null,
            errors: (error as any).response,
        };
    }
};

export { postCreditCardPayout, getCardDetails };
