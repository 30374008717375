import { useFormik, useFormikContext } from "formik";
import React from "react";
import { Bonus } from "./DepositBonus";

export type Bonuses = {
    label: string;
    description: string;
}

export type DepositBonusesProps = {
    bonuses: Bonuses[];
    bonusField: string;
}

const DepositBonuses: React.FC<DepositBonusesProps> = ({ bonuses, bonusField }) => {
    const formik = useFormikContext();

    const handleBonus = (bonus: string) => () => {
        formik.setFieldValue(bonusField, bonus)
    }

    return (
        <div className="BonusContainer">
            {
                bonuses.map(bonus => {
                    return (
                        <Bonus
                            label={bonus.label}
                            description={bonus.description}
                            onClick={handleBonus(bonus.label)}
                        />

                    )
                })
            }
        </div>
    )
}

export { DepositBonuses }