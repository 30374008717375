import { FormBuilder } from "@avamae/formbuilder";
import { ApiResponse } from "@avamae/formbuilder/dist/FormBuilder";
import { errorHandling } from "components/helpers/formHelpers/ErrorHandling";
import { EWalletPayoutDetails } from "components/pages/payout/models";
import { AmountStateProps } from "components/pages/payout/Payout";
import { Spinner } from "components/spinner/Spinner";
import { SuccessModal, SuccessProps } from "components/successModal/SuccessModal";
import { Formik, Form } from "formik";
import React, { useState } from "react"
import { PayoutLimits } from "../payoutLimits/PayoutLimits";
import { parseEWalletPayoutPayload, postEWalletPayout } from "./BitcoinPayoutApi";


const BitcoinPayout: React.FC<AmountStateProps> = ({ amount, currency }) => {
    const [error, setError] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);
    const [success, setSuccess] = useState<SuccessProps | null>(null);

    const initialValues = {
        payout__BtcAddress: "",
        payout__Amount: amount,
        payout__Comments: "",
        payout__Type: "",
        payout__Currency: currency,
        payout__playerId: "",
    }

    const handleSubmit = (FormikProps: EWalletPayoutDetails) => {
        setLoading(true)
        const payload: EWalletPayoutDetails = {
            payout__Amount: FormikProps.payout__Amount,
            payout__BtcAddress: FormikProps.payout__BtcAddress,
            payout__Type: "EWallet",
            payout__Currency: "USD",
            payout__playerId: "SW364495",
        };

        (async () => {
            console.log({ payload })
            const result = await postEWalletPayout(parseEWalletPayoutPayload(payload))
            if (result.response) {
                console.log({ "result.response": result.response })
                setSuccess({ html: result.response.details });
            }
            if (result.errors && result.errors.length > 0) {
                console.log(result.errors)
                setError(errorHandling(result.errors[0].code))
            }
        })()
        setLoading(false)
    }


    return (
        <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
        >
            <Form>
                <div className="PayoutContainer">
                    <h3>Payout using Bitcoin</h3>
                    <div className="FormBoxContainer">
                        <FormBuilder
                            loadingFallback={<Spinner />}
                            data={formData}
                            readOnlyFields={{ payout__Amount: true }}
                        />
                    </div>
                </div>
                <PayoutLimits minimum={100} maximum={5000} endText={false} />
                <div className="SubmitContainer">
                    <button className="SubmitBtn" type="submit">{loading ? <Spinner color="white" /> : 'Submit'}</button>
                </div>
                {success &&
                    <SuccessModal html={success.html} setSuccess={setSuccess} paymentType="Bitcoin" type="payout" />
                }
                {error &&
                    <div className="Error">{error}</div>
                }
            </Form>
        </Formik>
    )
}

export { BitcoinPayout };

const formData: ApiResponse = {
    id: 0,
    details: {},
    metadata: [
        {
            name: "payout__BtcAddress",
            type: "text",
            customType: null,
            label: "Bitcoin Address",
            children: []
        },
        {
            name: "payout__Amount",
            type: "text",
            customType: null,
            label: "Amount",
            children: []
        },
        {
            name: "payout__Comments",
            type: "textarea",
            customType: null,
            label: "Comments",
            children: []
        },

    ],
    status: "1",
    errors: []
}