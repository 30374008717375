import React, { useState } from "react";
import { Formik, Form } from "formik";
import { FormBuilder, FormDateTimeField, FormTextField } from '@avamae/formbuilder';
import { Spinner } from "components/spinner/Spinner";
import { ApiResponse, FieldRenderers } from "@avamae/formbuilder/dist/FormBuilder";
import * as Yup from "yup";
import { DepositBonuses, Bonuses } from "../depositBonuses/DepositBonuses";
import { DepositLimits } from "../depositLimits/DepositLimits";
import { parseCCDepositPayload, postCreditCardDeposit } from "./NewCreditCardDepositApi";
import { SubmitCCDepositDetails } from "./models";
import "react-datepicker/dist/react-datepicker.css";
import { useMedia } from "use-media";
import { errorHandling } from "components/helpers/formHelpers/ErrorHandling";
import { FormRow } from "components/formRow/FormRow";
import { SuccessModal, SuccessProps } from "components/successModal/SuccessModal";
import { useStore } from "reducers/Store";

const initialValues = {
    card__Type: "",
    card__Name: "",
    card__Number: null,
    card__ExpDate: null,
    card__CVV: null,
    card__Currency: "",
    card__DepositAmount: null,
    card__bSameAddress: false,
    card__BillingCountry: "",
    card__BillingState: "",
    card__BillingAddress: "",
    card__BillingCity: "",
    card__BillingZipCode: "",
    card__DepositBonus: null,
}
const NewCreditCardDeposit: React.FC<any> = () => {
    const store = useStore();
    const [error, setError] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);
    const [success, setSuccess] = useState<SuccessProps | null>(null);
    const isDesktop = useMedia({ minWidth: 850 })

    const handleSubmit = (values: SubmitCCDepositDetails) => {
        const payload: SubmitCCDepositDetails = {
            card__Type: values.card__Type,
            card__Name: values.card__Name,
            card__Number: values.card__Number,
            card__ExpDate: values.card__ExpDate,
            card__CVV: values.card__CVV,
            card__Currency: values.card__Currency,
            card__DepositAmount: values.card__DepositAmount,
            card__bSameAddress: values.card__bSameAddress,
            card__BillingCountry: values.card__BillingCountry,
            card__BillingState: values.card__BillingState,
            card__BillingAddress: values.card__BillingAddress,
            card__BillingCity: values.card__BillingCity,
            card__BillingZipCode: values.card__BillingZipCode,
            card__DepositBonus: values.card__DepositBonus,
        };

        (async () => {
            setLoading(true)
            console.log({ payload })
            const result = await postCreditCardDeposit(parseCCDepositPayload(payload, store.playerId))
            console.log({ result })
            if (result.response) {
                console.log("credit card post was a success")
                setSuccess({ address: result.response.details.address, html: result.response.details.embeddedHtml })
            }
            if (result.errors) {
                console.log({ "result.errors": result.errors })
                setError(errorHandling(result.errors[0].code))
                setLoading(false)
            }
        })()
    }

    const validationSchema = Yup.object({
        card__Type: Yup.string().required("Card type is required"),
        card__Name: Yup.string().required("Name is required"),
        card__Number: Yup.number().nullable().required("Card number is required"),
        card__ExpDate: Yup.string().nullable().required("Expiry date is required"),
        card__CVV: Yup.number().nullable().required("CVV is required"),
        card__Currency: Yup.string().required("Currency is required"),
        card__DepositAmount: Yup.number().nullable().required("An amount to be deposited is required"),
        card__BillingCountry: Yup.string().when("card__bSameAddress", { is: true, then: null, otherwise: Yup.string().required("Country is required") }),
        card__BillingState: Yup.string().when("card__bSameAddress", { is: true, then: null, otherwise: Yup.string().required("Please choose a state") }),
        card__BillingAddress: Yup.string().when("card__bSameAddress", { is: true, then: null, otherwise: Yup.string().required("An address is required") }),
        card__BillingCity: Yup.string().when("card__bSameAddress", { is: true, then: null, otherwise: Yup.string().required("Please choose a city") }),
        card__BillingZipCode: Yup.string().when("card__bSameAddress", { is: true, then: null, otherwise: Yup.string().required("Zip code is required") }),
        card__DepositBonus: Yup.string().required("Please select a bonus"),
    })

    const cardTypeDropdown = [{ value: "VISA", label: "VISA" }, { value: "Mastercard", label: "Mastercard" }, { value: "American Express", label: "American Express" }]
    const cardCurrencyDropdown = [{ value: "USD", label: "USD" }, { value: "GBP", label: "GBP" }, { value: "EUR", label: "EUR" }]

    const countryDropdown = [{ value: "US", label: "US" }]
    const stateDropdown = [{ value: "Alaska", label: "Alaska" }]

    const bonuses: Bonuses[] = [
        { label: "UFC123", description: "123% Sports bonus (max bonus $1,500) (20x rollover)" },
        { label: "NBA123", description: "123% Sports bonus (max bonus $1,500) (20x rollover)" },
        { label: "GOLF123", description: "123% Sports bonus (max bonus $1,000) (20x rollover)" },
        { label: "CAGEWARRIORS", description: "100% Cash bonus (max bonus $1,000) (20x rollover)" },
        { label: "CASINO250", description: "250% Casino bonus (max bonus $10,000) (80x rollover) (bonus play in rebate casino only)" },
        { label: "RE-UP15", description: "15% Sports cash bonus (max bonus $2,500) (6x rollover)" },
        { label: "NO BONUS", description: "1x rollover required of the deposit amount prior to requesting payout" },
    ]

    const cardFieldRenderers: FieldRenderers<any> = {
        card__ExpDate: (i, field) => (
            <div className="HalfWidthFields">
                <FormDateTimeField
                    fieldName={field.name as string}
                    label={field.label}
                    datePickerProps={{ dateFormat: "MM-yy", showMonthYearPicker: true }}
                />
                <FormTextField fieldName={"card__CVV"} label={"CVV"} />
            </div>
        )
    }

    const billingFieldRenderers = (formikProps: any): FieldRenderers<any> => ({
        card__bSameAddress: (i, field) => (
            <div className="FormBox FormSideBySide">
                <label className="container">{field.label}
                    <input
                        key={i}
                        name={field.name as string}
                        type="checkbox"
                        id={field.name.toString()}
                        checked={!!formikProps.values[field.name]}
                        onChange={() => formikProps.setFieldValue(field.name, !formikProps.values[field.name])}
                    />
                    <span className="checkmark"></span>
                </label>
            </div>
        )
    })

    const mobCardFieldRenderers: FieldRenderers<any> = {
        card__ExpDate: (i, field) => (
            <div className="HalfWidthFields">
                <FormDateTimeField
                    fieldName={field.name as string}
                    label={field.label}
                />
                <FormTextField fieldName={"card__CVV"} label={"CVV"} />
            </div>
        )
    }

    const mobBillingFieldRenderers = (formikProps: any): FieldRenderers<any> => ({
        card__bSameAddress: (i, field) => (
            <div className="FormBox FormSideBySide">
                <label className="container">{field.label}
                    <input
                        key={i}
                        name={field.name as string}
                        type="checkbox"
                        id={field.name.toString()}
                        checked={!!formikProps.values[field.name]}
                        onChange={() => formikProps.setFieldValue(field.name, !formikProps.values[field.name])}
                    />
                    <span className="checkmark"></span>
                </label>
            </div>
        )
    })



    return (
        <>
            <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={validationSchema}
            >
                {formikProps => (
                    <Form>
                        <>
                            {isDesktop ?
                                <>
                                    <div className="FormContainer">

                                        <div className="FormRow FormRowHeaders">
                                            <h3>Card Details</h3>
                                            <h3>Billing Details</h3>
                                        </div>
                                        <FormRow data={dataRowOne} additionalData={{ card__Type: cardTypeDropdown }} overrideFormFieldRenderer={billingFieldRenderers(formikProps)}
                                        />
                                        <FormRow data={dataRowTwo} additionalData={{ card__BillingCountry: countryDropdown }} />
                                        <FormRow data={dataRowThree} additionalData={{ card__BillingState: stateDropdown }} />
                                        <FormRow data={dataRowFour} hiddenFields={{ card__CVV: true }} overrideFormFieldRenderer={cardFieldRenderers} />
                                        <FormRow data={dataRowFive} additionalData={{ card__Currency: cardCurrencyDropdown }} />
                                        <FormRow data={dataRowSix} />
                                        <div className="Divider"></div>
                                    </div>
                                    <DepositLimits minimum={45} maximum={1500} />
                                    <DepositBonuses bonuses={bonuses} bonusField={'card__DepositBonus'} />
                                    <div className="SubmitContainer">
                                        <button type="submit" className="SubmitBtn">Deposit</button>
                                    </div>
                                    {error &&
                                        <div className="ErrorMessage">
                                            {error}
                                        </div>
                                    }
                                    {success &&
                                        <SuccessModal html={success.html} address={success.address} setSuccess={setSuccess} paymentType="credit card" type="deposit" />
                                    }
                                </>
                                :
                                <>
                                    <div className="FormContainer">
                                        <div className="FormSectionMob">
                                            <h3>Card Details</h3>
                                            <FormBuilder
                                                data={mobCardData}
                                                overrideFormFieldRenderer={mobCardFieldRenderers}
                                                loadingFallback={<Spinner />}
                                                additionalData={{ card__Type: cardTypeDropdown, card__Currency: cardCurrencyDropdown }}
                                                hiddenFields={{ card__CVV: true }}
                                            // fieldProps={{ card__BillingCountry: { defaultMenuIsOpen: true } }}
                                            />
                                        </div>
                                        {/* <div className="Divider"></div> */}
                                        <div className="FormSectionMob">
                                            <h3>Billing Details</h3>
                                            <FormBuilder
                                                data={mobBillingData}
                                                overrideFormFieldRenderer={mobBillingFieldRenderers(formikProps)}
                                                loadingFallback={<Spinner />}
                                                additionalData={{ card__BillingCountry: countryDropdown, card__BillingState: stateDropdown }}
                                                fieldProps={{ card__ExpDate: { dateFormat: "MM:yyyy" } }}
                                            />
                                        </div>
                                        <DepositLimits minimum={45} maximum={1500} />
                                        <div className="DepositBonusesMob">
                                            <DepositBonuses bonuses={bonuses} bonusField={'card__DepositBonus'} />
                                        </div>
                                    </div>
                                    <div className="SubmitContainer">
                                        <button type="submit" className="SubmitBtn">{loading ? <Spinner color="white" /> : 'Deposit'}</button>
                                    </div>
                                    {error &&
                                        <div className="ErrorMessage">
                                            {error}
                                        </div>
                                    }
                                    {success &&
                                        <SuccessModal html={success.html} address={success.address} setSuccess={setSuccess} paymentType="credit card" type="deposit" />
                                    }
                                </>
                            }
                        </>
                    </Form>
                )}
            </Formik>
        </>
    )
}

export { NewCreditCardDeposit };

const dataRowOne: ApiResponse<any> = {
    id: 0,
    details: {},
    metadata: [
        {
            name: "card__Type",
            type: "singleselect",
            customType: null,
            label: "Card type",
            children: []
        },
        {
            name: "card__bSameAddress",
            type: "custom",
            customType: null,
            label: "Tick this box if your home address is the same as billing address",
            children: []
        },
    ],
    status: "1",
    errors: []
}
const dataRowTwo: ApiResponse<any> = {
    id: 0,
    details: {},
    metadata: [
        {
            name: "card__Name",
            type: "text",
            customType: null,
            label: "Cardholder's name",
            children: []
        },
        {
            name: "card__BillingCountry",
            type: "singleselect",
            label: "Address",
            children: []
        },
    ],
    status: "1",
    errors: []
}
const dataRowThree: ApiResponse<any> = {
    id: 0,
    details: {},
    metadata: [
        {
            name: "card__Number",
            type: "number",
            customType: null,
            label: "Credit card number",
            children: []
        },
        {
            name: "card__BillingState",
            type: "singleselect",
            label: "State",
            children: []
        },
    ],
    status: "1",
    errors: []
}
const dataRowFour: ApiResponse<any> = {
    id: 0,
    details: {},
    metadata: [
        {
            name: "card__ExpDate",
            type: "custom",
            customType: null,
            label: "Exp Date",
            children: []
        },
        {
            name: "card__CVV",
            type: "number",
            customType: null,
            label: "CVV",
            children: []
        },
        {
            name: "card__BillingAddress",
            type: "text",
            customType: null,
            label: "Address",
            children: []
        },
    ],
    status: "1",
    errors: []
}
const dataRowFive: ApiResponse<any> = {
    id: 0,
    details: {},
    metadata: [
        {
            name: "card__Currency",
            type: "singleselect",
            customType: null,
            label: "Currency",
            children: []
        },
        {
            name: "card__BillingCity",
            type: "text",
            customType: null,
            label: "City",
            children: []
        },
    ],
    status: "1",
    errors: []
}
const dataRowSix: ApiResponse<any> = {
    id: 0,
    details: {},
    metadata: [
        {
            name: "card__DepositAmount",
            type: "number",
            customType: null,
            label: "Amount",
            children: []
        },
        {
            name: "card__BillingZipCode",
            type: "text",
            customType: null,
            label: "ZIP code",
            children: []
        },
    ],
    status: "1",
    errors: []
}


const mobCardData: ApiResponse = {
    id: 0,
    details: {},
    metadata: [
        {
            name: "card__Type",
            type: "singleselect",
            customType: null,
            label: "Card type",
            children: []
        },
        {
            name: "card__Name",
            type: "text",
            customType: null,
            label: "Cardholder's name",
            children: []
        },
        {
            name: "card__Number",
            type: "text",
            customType: null,
            label: "Credit card number",
            children: []
        },
        {
            name: "card__ExpDate",
            type: "custom",
            customType: null,
            label: "Exp Date",
            children: []
        },
        {
            name: "card__CVV",
            type: "text",
            customType: null,
            label: "CVV",
            children: []
        },
        {
            name: "card__Currency",
            type: "singleselect",
            customType: null,
            label: "Currency",
            children: []
        },
        {
            name: "card__DepositAmount",
            type: "text",
            customType: null,
            label: "Amount",
            children: []
        },

    ],
    status: "1",
    errors: []
}
const mobBillingData: ApiResponse = {
    id: 0,
    details: {},
    metadata: [
        {
            name: "card__bSameAddress",
            type: "custom",
            customType: null,
            label: "Tick this box if your home address is the same as billing address",
            children: []
        },
        {
            name: "card__BillingCountry",
            type: "singleselect",
            label: "Address",
            children: []
        },
        {
            name: "card__BillingState",
            type: "singleselect",
            label: "State",
            children: []
        },
        {
            name: "card__Address",
            type: "text",
            customType: null,
            label: "Address",
            children: []
        },
        {
            name: "card__City",
            type: "text",
            customType: null,
            label: "City",
            children: []
        },
        {
            name: "card__ZipCode",
            type: "text",
            customType: null,
            label: "ZIP code",
            children: []
        },
    ],
    status: "1",
    errors: []
}