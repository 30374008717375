import React, { SetStateAction } from "react";

interface SuccessModalProps {
    html: string;
    setSuccess: React.Dispatch<SetStateAction<SuccessProps | null>>;
    type: string;
    paymentType: string;
    address?: string;
}

export type SuccessProps = {
    address?: string;
    html: string;
}

const SuccessModal: React.FC<SuccessModalProps> = ({ address, html, setSuccess, type, paymentType }) => {

    const handleClose = () => {
        setSuccess(null)
    }

    return (
        <div className="SuccessModal">
            <div className="SuccessContainer">
                <h2>{paymentType} {type} completed</h2>
                <div className="SuccessContent">
                    <div className="SuccessHTML" dangerouslySetInnerHTML={{ __html: html }}></div>
                    {address ?
                        <p>Address: {address}</p>
                        :
                        null
                    }
                </div>
                <div className="CloseModalBtn" onClick={handleClose}>Close</div>
            </div>
        </div>
    )
}

export { SuccessModal };