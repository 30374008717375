import React from "react";
import { NavItem } from "components/navItem/NavItem";
import { DepositTabRouter } from "components/mainRouter/MainRouter";


const Deposit = () => {

    return (
        <section className="Page Deposit">
            <h2>Deposit</h2>
            <div className="Main">
                <nav className="Nav">
                    <div className="Links">
                        <NavItem active="credit card" />
                        <NavItem active="credit card new" />
                        <NavItem active="bitcoin" />
                        <NavItem active="crypto" />
                        <NavItem active="other" />
                    </div>
                    <div className="BorderBottom"></div>
                </nav>
                <div className="Content">
                    <DepositTabRouter />
                </div>
            </div>
        </section>
    )
}

export { Deposit }